import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import PackageCard from "../components/package.card";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config/apiroutes";
import AlertConfirm from '../components/confirmalert';
let selPackage = null;

const PackageList = () => {
  const [packages, setPackages] = useState([]);

  const [showAlert, setShowAlert] = useState(false);

  const handleResponse = (res) => {
    if(res){
      setPackages(res.data);
    }else{
      setPackages([])
    }
  }

  const { getPackages, delPackage } = ApiRoutes;

  const { sendRequest: fetchPackages, error } = useHttp(handleResponse);

  const {
    sendRequest: deletePackage,
    error: delError,
    isLoading: delLoading,
  } = useHttp(() => {setShowAlert(false)});

  const handleDelete = (item) => {
    setShowAlert(true);
    selPackage = item;
  };
  
  // Handle Cancel
  const handleCancel = () => {
    setShowAlert(false);
  }
  
  
  const handleConfirm = () => {
    deletePackage({ url: delPackage, method: "POST", body: selPackage })
  }

  useEffect(() => {
    fetchPackages({ url: getPackages });
    if (delLoading && delError) {
      fetchPackages({ url: getPackages });
    }
  }, [fetchPackages, delLoading, delError]);

  return (
    <Fragment>
      <div className="topSpacer"></div>

      {showAlert && (
        <AlertConfirm
          open={showAlert}
          title={`Delete ${selPackage.name}`}
          message="Deleting can't be Reversed. Are You Sure ?"
          confirm={handleConfirm}
          isLoading={delLoading}
          onCancel={handleCancel}
        />
      )}
      <div className="container m-auto">
        <div className="flex md:justify-end justify-center">
          <Link
            to="/add-package"
            className="bg-blue-500 rounded-lg text-white text-center px-4 h-9 leading-h-9 max-w-fit w-full flex md:justify-end justify-center mb-4 items-center"
          >
            <PlusIcon className="w-4 h-4 fill-white inline-block mr-2" />
            <span>Add Package</span>
          </Link>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
          {packages.map((item, index) => (
            <PackageCard packages={item} key={index} onDelete={handleDelete} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default PackageList;
