import React from "react";

import nodata from "../nodata.png"
const NoData = ({ title, message }) => {
  return (
    <div className="no-data">
        <div className="m-auto w-36 h-36">
            <img src={nodata} />
        </div>
      <div className="inner-content">
        <h4>{title ? title : "Sorry No Data Found"}</h4>
        <h6>{message ? message : 'No data found for this request'}</h6>
      </div>
    </div>
  );
};

export default NoData;
