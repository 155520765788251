import React , { useEffect, useState } from "react";
import { CurrencyRupeeIcon } from "@heroicons/react/outline";
import useHttp from "../../hooks/use-http";
import { ApiRoutes } from "../../config";

import { FormatDate } from "../../helper";

let cardBg = "#38bdf8";

const TransactionCard = ({
  transaction,
  paymentStatus,
  onStatusChange,
  editable = false,
}) => {
  const { sendRequest } = useHttp((resp) => {
    const { data } = resp;
    onStatusChange({ id: data.id, status: +data.payment_status });
  });

  const { paymentStatusUpdate } = ApiRoutes;

  const [currStat, setCurrStat] = useState(transaction.payment_status);

  const statusChange = (e) => {
    let value = e.target.value;
    setCurrStat(value);
    sendRequest({
      url: paymentStatusUpdate,
      method: "POST",
      body: { id: transaction.id, payment_status: value },
    });
  };

  const cardColor = (value) => {
    switch (value) {
      case 1:
        return "#38bdf8";
      case 2:
        return "#f43f5e";
      case 3:
        return "#10b981";
      default:
        return "#38bdf8";
    }
  };

  const selectColor = (value) => {
    switch (value) {
      case 1:
        return "#38bdf8";
      case 2:
        return "#f43f5e";
      case 3:
        return "#10b981";
      default:
        return "#38bdf8";
    }
  };

  const payment_label = currStat === 1 ? 'Clearing upto' : 'Cleared Upto'

  return (
    <div className={`trans-card border-l-2 shadow-sm card-${currStat}`}>
      <div className="text-gray-700 text-xs md:flex items-center">
        <FormatDate>{new Date(transaction.created_at).toDateString()}</FormatDate>
      </div>
      <div className="text-gray-700 text-xs sm:text-left text-right md:col-span-1 col-span-3 md:flex items-center">
        <span>{transaction.payment_method}</span>
      </div>
      <div className="reason md:flex items-center">
      <span>
        {currStat !== 2 && <span>&nbsp; {payment_label} {new Date(transaction.payment_date).toLocaleDateString()}</span>}
      </span>
        <span>{transaction.payment_details}</span>
      </div>
      <div className="text-base text-right text-neutral-800 font-medium whitespace-nowrap md:flex items-center">
        <span>
          <CurrencyRupeeIcon className="inline w-4 h-4 stroke-slate-400 relative -top-0.5 -left-0.5" />
          {transaction.amount}
        </span>
      </div>
      <div className="md:col-span-1 col-span-4 md:text-left text-right">
        <select
          className={`smSelect bg-white ${editable ? "teacherSel" : ""
            } rounded-md`}
          onChange={statusChange}
          value={currStat}
          disabled={editable}
        >
          {paymentStatus.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TransactionCard;
