import React from "react";

import StudProfile from "../studprofile";
import {
  PencilIcon,
  TrashIcon,
  EyeIcon,
  BookOpenIcon,
  UserGroupIcon,
  PhoneOutgoingIcon,
  LockOpenIcon
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
const TutorCard = ({ tutor, onDelete, onCredUpdate }) => {
  return (
    <div className="student-card pb-20 tutor-card-temp">
      <div className="stud-card-head">
        <StudProfile name={tutor.name} badge={tutor.warninig} />
        <div className="">
          <h6 className="text-xs text-gray-500">{tutor.teacher_id}</h6>
          <h3 className="capitalize" title={tutor.name}>
            {tutor.name}
          </h3>
        </div>
        <div className="ml-auto flex ">
          <a className="md:mr-5 mr-7" href={`tel:${tutor.phone_no}`} title="Call Now">
            <PhoneOutgoingIcon className="w-4 h-4 fill-blue-600" />
          </a>
          <Link to={`/tutor-edit/${tutor.id}`} className="">
            <PencilIcon className="action-icon fill-blue" />
          </Link>
        </div>
      </div>
      {tutor.warninig && (
        <div className="info-incomplete">
          <p>{tutor.message}</p>
        </div>
      )}
      <div className="student-meta">
        <div className="meta-detail flex justify-between mb-1">
          <div className="text-sm text-slate-600 ">
            <BookOpenIcon className="inline w-5 h-4 fill-blue-00 mr-1" /> Since
          </div>
          <div className="text-sm text-slate-800 font-normal ml-auto inline-block">
            {tutor.days}
          </div>
        </div>
        <div className="meta-detail flex justify-between">
          <div className="text-sm text-slate-600 ">
            <UserGroupIcon className="inline w-5 h-4 fill-blue-00 mr-1" />{" "}
            Active Students
          </div>
          <div className="text-sm text-slate-800 font-normal ml-auto inline-block">
            {tutor.student_count}
          </div>
        </div>
      </div>
      {tutor.subjects.length > 0 && (
        <div className="subject_table">
          <table className="w-full">
            <thead>
              <tr>
                <th className="py-1 px-2 bg-indigo-200 text-slate-600 text-sm text-left font-normal">
                  Subjects
                </th>
                <th className="py-1 px-2 bg-indigo-200 text-slate-600 text-sm text-right font-normal">
                  Rate/Class.
                </th>
              </tr>
            </thead>
            <tbody>
              {tutor.subjects.map((subject) => (
                <tr key={subject.id}>
                  <td>{subject.name}</td>
                  <td>{subject.rate_per_hr}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="action-panel bg-indigo-500 rounded-b-md absolute bottom-0 w-full pt-4">
        <ul className="grid grid-cols-2 gap-1">
          <li>
            <Link to={`/tutor-view/${tutor.id}`} className="w-full">
              <EyeIcon className="action-icon fill-white" />
              <h5 className="text-smaller text-white">Details</h5>
            </Link>
          </li>
          <li>
            <button className="w-full" onClick={onCredUpdate.bind(this, ({id: tutor.id, trId: tutor.teacher_id, password: tutor.password}))}>
              <LockOpenIcon className="action-icon fill-white" />
              <h5 className="text-smaller text-white">Change Credentials</h5>
            </button>
          </li>
          {/* <li>
            <button className="w-full" onClick={onDelete.bind(this, tutor)}>
              <TrashIcon className="action-icon fill-white" />
              <h5 className="text-smaller text-white">Delete</h5>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default TutorCard;
