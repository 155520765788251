import React from "react";

import { CloudDownloadIcon } from "@heroicons/react/outline";
const ShowMore = ({ btnloading, toggleEvent }) => {
  return (
    <div className="inline-block w-full mt-4 text-center">
      <button
        onClick={toggleEvent}
        className="bg-indigo-600 rounded-md md:w-44 max-w-xs leading-9 h-9 w-full text-white"
        disabled={btnloading}
      >
        <CloudDownloadIcon className="w-4 h-4 inline-block mr-1 stroke-white" />
        <span>See More</span>
      </button>
    </div>
  );
};

export default ShowMore;
