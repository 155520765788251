import React from "react";

import StudentCardSmall from "../student/studentcard.sm";
import ActiveStudentsCard from "./activestudent.card";

const TutorStudent = ({ activeStudents }) => {
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 grid-cols-1">
      {activeStudents.map((student) => (
        <ActiveStudentsCard
          key={student.id}
          student={student}
          onLedgerAdd={() => {}}
          isEditable={false}
        />
      ))}
    </div>
  );
};

export default TutorStudent;
