import React from 'react';
import GreetImg from "../assets/banner.svg"
const GreetingComponent = () => {
  return (
    <div className="greetings">
      <h3>Welcome back, Admin</h3>
      <h6>There is nothing impossible to they who will try.</h6>
      <figure className="absolute right-0 bottom-0 w-52 h-auto top-auto sm:block hidden"><img src={GreetImg} className="ml-auto" width="200" height="115" /></figure>
    </div>
  );
};

export default GreetingComponent;
