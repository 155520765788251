import React from 'react';
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/requireauth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainLayout from "./components/layout";
import TeacherLayout from "./components/teacherlayout";
import LoginScreen from "./pages/LoginScreen";
import Dashboard from "./pages/Dashboard";
import StudentList from "./pages/StudentList";
import SubjectMaster from "./pages/SubjectMaster";
import PackageList from "./pages/PackageList";
import PackageCrud from "./pages/PackageCRUD";
import StudentCrud from "./pages/StudentCRUD";
import StudentPackages from "./pages/StudentPackages";
import TeachersList from "./pages/TeachersList";
import TeacherCrud from "./pages/TeacherCRUD";
import AssignTutor from "./pages/AssignTutor";
import TutorDetails from "./pages/TutorDetail";
import TeacherRenum from "./pages/TeacherRenum";
import ErrorPage from "./pages/ErrorPage";
import TeacherLanding from "./pages/TeacherLanding";
import RoleAuth from "./components/roleauth";
import ChangePassword from "./pages/ChangePassword";
import TeacherProfile from './pages/TeacherProfile';
function App() {
  toast.configure({
    position: "bottom-center",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    limit: 1,
  });
  
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        }
      >
        <Route
          index
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/subjects"
          element={
            <RequireAuth>
              <SubjectMaster />
            </RequireAuth>
          }
        />
        <Route
          path="/packages"
          element={
            <RequireAuth>
              <PackageList />
            </RequireAuth>
          }
        />
        <Route
          path="/add-package"
          element={
            <RequireAuth>
              <PackageCrud />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-package/:pId"
          element={
            <RequireAuth>
              <PackageCrud />
            </RequireAuth>
          }
        />
        <Route
          path="/students"
          element={
            <RequireAuth>
              <StudentList />
            </RequireAuth>
          }
        />
        <Route
          path="/create-student"
          element={
            <RequireAuth>
              <StudentCrud />
            </RequireAuth>
          }
        />
        <Route
          path="/student/:id"
          element={
            <RequireAuth>
              <StudentCrud />
            </RequireAuth>
          }
        />
        <Route
          path="/student-package/:id"
          element={
            <RequireAuth>
              <StudentPackages />
            </RequireAuth>
          }
        />
        <Route
          path="/teachers"
          element={
            <RequireAuth>
              <TeachersList />
            </RequireAuth>
          }
        />
        <Route
          path="/create-tutor"
          element={
            <RequireAuth>
              <TeacherCrud />
            </RequireAuth>
          }
        />
        <Route
          path="/tutor-edit/:id"
          element={
            <RequireAuth>
              <TeacherCrud />
            </RequireAuth>
          }
        />
        <Route
          path="/teacher-profile/:id"
          element={
            <RequireAuth>
              <TeacherProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/tutor-renum/:id"
          element={
            <RequireAuth>
              <TeacherRenum />
            </RequireAuth>
          }
        />
        <Route
          path="/assign-tutor/:id"
          element={
            <RequireAuth>
              <AssignTutor />
            </RequireAuth>
          }
        />
        <Route
          path="/tutor-view/:id"
          element={
            <RequireAuth>
              <TutorDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/change-password"
          element={
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          }
        />
    
      </Route>
      <Route path="/teacher-dashboard" element={<TeacherLayout />}>
        <Route index element={<RoleAuth><TeacherLanding /></RoleAuth>} />
        <Route
          path="/teacher-dashboard/change-password"
          element={
            <RoleAuth>
              <ChangePassword />
            </RoleAuth>
          }
        />
      </Route>
     
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
