import React from 'react';
import PackageProgress from "./package.progress";

const PackageStats = ({item}) => {
  return (
    <div
      className="mb-3 border-b-tiny border-slate-100 last-of-type:border-0 pb-4 last:pb-0"
    >
      <div className="package-stats-prog">
        <div className="mb-2">
          <h6>
            {item.package_name}
            <span className="float-right text-sm text-slate-400">
              {item.no_of_days} D
            </span>
          </h6>
        </div>
        <PackageProgress packageStats={item.percntage} />
        <div className="flex justify-between mt-1">
          <div className="text-sm">
            <span className="inline text-xs text-gray-400 mr-1">
              Completed Class.
            </span>
            {Math.trunc(item.completedHrs)} Class.
          </div>
          <div className="text-sm">
            <span className="inline text-xs text-gray-400 mr-1">
              Total Class.
            </span>
            {Math.trunc(item.totalHrs)} Class.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageStats;
