import React, { useEffect, useState, Fragment, forwardRef, useImperativeHandle } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import LedgerCard from "./ledger.card";
import {
  CurrencyRupeeIcon,
  SearchIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import { ApiRoutes } from "../../config";
import useHttp from "../../hooks/use-http";
import AlertConfirm from "../confirmalert";
import { filterOptions } from "../../helper/_helper";


let today = "".concat(new Date().toISOString().split("T")[0]);

let date = new Date();
date.setDate(date.getDate() - 1);
let maxDate = date.toISOString().split("T")[0];

const WorkLedgerPayment = forwardRef(({ id, onPayNow, teacherId, activeStudents, onFetchLogSucess, lastPaymentDt }, ref) => {
  const { fetchAmountToBePaidWithinDate, tutorWorkLog } = ApiRoutes;

  const [filterData, setFilterData] = useState({
    id: teacherId,
    type: filterOptions[0].value,
  });

  const [workLog, setWorkLog] = useState([]);

  const [totalAmount, setTotalAmount] = useState("");

  const [totalHrs, setTotalHrs] = useState("");

  const [selectFilter, setSelectFilter] = useState(filterOptions[0]);

  const [filterStud, setFilterStud] = useState(null);

  const [showAlert, setShowAlert] = useState(false);

  const [paymentInit, setPaymentInit] = useState({
    initiatedStat: 0,
    initiatedAmount: "",
  });

  const handleChange = (filterOpt) => {
    setSelectFilter(filterOpt);
  };

  const _handleworkledge = (response) => {
    const { data } = response;
    setPaymentInit({
      initiatedStat: data.warning,
      initiatedAmount: data.initated_amount,
    });
    setWorkLog(data.log);
    setTotalAmount(data.total);
    setTotalHrs(data.total_hours);
    onFetchLogSucess(data.last_paid_date)
  };

  const handleDtChange = (event) => {
    const value = event.target.valueAsDate;
    setFilterData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: value,
      };
    });
  };


  const {
    sendRequest: fetchAmountToPaid,
    isLoading: amount_loading
  } = useHttp((res) => {
    let label = selectFilter.label;
    const logData = { totalAmount: res?.data, label };
    onPayNow(logData);
  })

  const handlePaymentDate = (e) => { 
    const end_date = maxDate;
    fetchAmountToPaid({
      url: fetchAmountToBePaidWithinDate,
      method: 'POST',
      body: {
        id: id,
        start_date: lastPaymentDt,
        end_date
      }
    })
  };

  const handlePaynowModal = () => {
    if(!paymentInit.initiatedStat){
      handlePaymentDate();
    }else{
      setShowAlert(true);
    }    
  };
  const { sendRequest: fetchworklog } = useHttp(_handleworkledge);

  const filterCustomDate = () =>
    fetchworklog({
      url: tutorWorkLog,
      method: "POST",
      body: filterData,
    });

  const _handleFilterStudChange = (data) => setFilterStud(data);


  useImperativeHandle(ref, () => ({
    _updateLedgerPay() {
      let studentId = filterStud ? filterStud.id : '';
      let type = "";
      if (selectFilter.value !== 0) {
        type = selectFilter.value;
      }
      fetchworklog({
        url: tutorWorkLog,
        method: "POST",
        body: { ...filterData, type, student_id: studentId },
      });
    }
  }))

  const handleCancelAlert = () => setShowAlert(false);
  useEffect(() => {
    let studentId = filterStud ? filterStud.id : '';
    if (selectFilter.value !== 0) {
      let type = selectFilter.value;
      fetchworklog({
        url: tutorWorkLog,
        method: "POST",
        body: { ...filterData, type, student_id: studentId },
      });
    }
  }, [selectFilter, filterStud]);


  return (
    <Fragment>
      <div className="filter_menu">
        <div className="md:w-60 w-full">
          <Select
            onChange={handleChange}
            options={filterOptions}
            isMulti={false}
            isSearchable={false}
            value={selectFilter}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            className="filter_sel"
          />
        </div>
        <div className="md:w-60 w-full">
          <Select
            onChange={_handleFilterStudChange}
            options={activeStudents}
            isMulti={false}
            isSearchable={false}
            isClearable={true}
            value={filterStud}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            className="filter_sel"
            placeholder="Select Student"
          />
        </div>
        {selectFilter.value == 0 && (
          <div className="md:ml-auto md:w-fit w-full flex md:justify-between justify-center items-center md:mb-0 mt-3">
            <div className="md:mx-0 mx-2">
              <input
                type="date"
                placeholder="From Date"
                className="common-inp datepicker_range"
                id="fromdate"
                name="start_date"
                onChange={handleDtChange}
              />
            </div>
            <div className="md:ml-3 mr-0 md:mx-0 mx-2">
              <input
                type="date"
                placeholder="To Date"
                className="common-inp datepicker_range"
                id="todate"
                name="end_date"
                max={today}
                onChange={handleDtChange}
              />
            </div>
            <button className="filter-btn" onClick={filterCustomDate}>
              <SearchIcon className="sm-icon stroke-white m-auto" />
            </button>
          </div>
        )}
      </div>
      {workLog.length > 0 && (
        <Fragment>
          <div className="filter_result mb-2 justify-end items-center">
            <h4 className="text-sm hidden">Amount for the {selectFilter.label}</h4>
            <div className="flex items-baseline">
              <h3 className="font-medium hidden">
                Total Classes. <ClockIcon className="sm-icon" /> {Math.trunc(totalHrs)}{" "}
                <small>Class.</small>
              </h3>
              <button
                className="text-xs inline px-2 rounded-sm leading-5 ml-1 mr-2 transition-all font-medium bg-purple-800 text-white"
                onClick={handlePaynowModal}
                disabled={totalAmount === 0}
              >
                Pay Now
              </button>
              <h3 className="font-medium">
                <span className="text-xs">Balance</span> <small>INR</small> <span className="text-md">{totalAmount}{" "}</span>

              </h3>

            </div>
          </div>
        </Fragment>
      )}
      {workLog.map((log) => (
        <LedgerCard key={log.type + log.id} ledger={log} />
      ))}

      <AlertConfirm
        open={showAlert}
        title='Another Payment is already initiated !'
        message={`Payment of ${paymentInit.initiatedAmount} is already initiated, Please complete or cancel it before initiating new payment.`}
        confirm={() => { }}
        onCancel={handleCancelAlert}
        confirmBtn="false"
        cancelBtnTxt="Okay !"
      />
    </Fragment>
  );
});

export default WorkLedgerPayment;
