import React , { useState } from "react";
import { useParams } from "react-router-dom";
import PackageForm from "../modules/PackageForm";

const PackageCrud = () => {
  const {pId} = useParams();
  return (
    <div className="container m-auto">
      <div className="bg-white rounded-md shadow-sm md:py-9 md:p-0 p-4">
        <div className="max-w-screen-sm w-full m-auto border-b-tiny border-gray-300 mb-5">
          <h3 className="mb-3 font-semibold">{!pId ? 'Add Package' : 'Edit Package'}</h3>
        </div>
        <PackageForm id={pId} />
      </div>
    </div>
  );
};

export default PackageCrud;
