import React from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, Fragment } from "react";
import MainNav from "./mainnav";
import { useContext } from "react";
import AuthContext from "../context/auth-context";
import Loader from "./loader";
import { HomeIcon } from "@heroicons/react/outline";


export const ScrollTop = () => {

  const pathname = useLocation();

  const auth = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  return null;
}



const MainLayout = () => {
  const auth = useContext(AuthContext);
  const { isLoading } = auth;
  const year = new Date().getFullYear();
  const navigation = [
    { name: "Dashboard", href: "/" },
    { name: "Teachers", href: "/teachers" },
    { name: "Students", href: "/students" },
    { name: "Subjects", href: "/subjects" },
    { name: "Packages", href: "/packages" },
  ];
  return (
    <Fragment>
      <ScrollTop />
      <MainNav />
      <div className="lg:px-0 pa bg-slate-200 pb-5 min-h-screen inline-block w-full">
        {isLoading && <Loader />}
        <Outlet />
      </div>
     
      <footer className="p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 text-center">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {year}
            <a href="#" className="hover:underline">
            &nbsp;Learn and Grow Academy
            </a>
            . All Rights Reserved.
          </span>
        </footer>
    </Fragment>
  );
};

export default MainLayout;
