import React, { Fragment, useEffect, useState } from "react";
import TransactionCard from "./transactioncard";
import useHttp from "../../hooks/use-http";
import { ApiRoutes } from "../../config";
const Transactions = ({ id, updated }) => {
  const { paymentHistory, getStatus } = ApiRoutes;

  const [payments, setPayments] = useState([]);

  const [paymentStatus, setPaymentStatus] = useState([]);

  const fetchPayment = (res) => setPayments(res.data);

  const handleRepsonse = (res) => setPaymentStatus(res.data);

  const { sendRequest } = useHttp(fetchPayment);

  const { sendRequest: fetchStatus } = useHttp(handleRepsonse);

  const handleStatusChange = (data) => {
    const { id, status } = data;
    const newArray = payments;
    const indexEdit = payments.findIndex((x) => x.id == id);
    newArray[indexEdit].payment_status = +status;
    setPayments(newArray);
  };

  useEffect(() => {
    sendRequest({ url: `${paymentHistory}/${id}` });
    fetchStatus({ url: getStatus });
    if (updated) {
      sendRequest({ url: `${paymentHistory}/${id}` });
    }
  }, [updated]);

  return (
    <Fragment>
      {payments.map((item, index) => (
        <TransactionCard
          transaction={item}
          key={item.id}
          paymentStatus={paymentStatus}
          onStatusChange={handleStatusChange}
          editable={index != 0}
        />
      ))}
    </Fragment>
  );
};

export default Transactions;
