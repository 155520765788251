import React , { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config/apiroutes";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import AlertConfirm from "../components/confirmalert";
import SubjectHours from "../components/subjecthours";
import { ErrorMessage } from '@hookform/error-message';
import { PackageMinOptions } from "../helper/_helper";
let removeObje = null;
let remainSel = [];

const PackageForm = (props) => {
  const [subjOpt, setSubjOpt] = useState([]);

  const [subjects, setSubjects] = useState([]);

  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleResponse = (res) => navigate("/packages", { replace: false });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    control
  } = useForm();

  const { sendRequest, error, isLoading } = useHttp(handleResponse);

  // Subjects Master
  const fetchSubjectsRes = (subjects) => {
    const { data } = subjects;
    const newArray = data.map((item) => ({
      ...item,
      subject_id: item.id,
      subject_name: item.name,
      id: 0,
    }));
    setSubjOpt(newArray);
  };

  const { id } = props;

  const { addPackage, editPackage, delPackageSubj, getSubjects, getPackages } =
    ApiRoutes;

  const {
    sendRequest: fetchSubjects,
    error: subjError,
    isLoading: subjLoading,
  } = useHttp(fetchSubjectsRes);

  // onChange
  const handleSelChange = (e, event) => {
    if (event.action == "remove-value") {
      removeObje = event.removedValue;
      setShowAlert(true);
      remainSel = e;
      return;
    }
    setSubjects(e);
  };

  // Show alert popup hide
  const onCancel = () => setShowAlert(false);

  // Subject delete init
  const initDelete = () => {
    deleteSubjectEntry({
      url: delPackageSubj,
      method: "POST",
      body: removeObje,
    });
  };

  // Remove Subjects after delete
  const removeSubject = (res) => {
    setShowAlert(false);
    setSubjects(remainSel);
  };

  const {
    sendRequest: deleteSubjectEntry,
    isLoading: delSubEntry,
    error: delSubEntryErr,
  } = useHttp(removeSubject);

  // Subject Add Hours
  const addHours = (data) => {
    let {target, value, subjId} = data;
    const index = subjects.findIndex((x) => x.subject_name === target);
    subjects[index].no_of_hrs = value;
  };

  // OnSubmit function
  const packageSubmit = (data) => {
    var newPackage = { ...data, subjects, id };
    let url = !id ? addPackage : editPackage;
    let urlConfig = {
      url,
      method: "POST",
      body: newPackage,
    };
    sendRequest(urlConfig);
  };

  // Write Form Values
  const handlePckgDetails = (res) => {
    const { data } = res;
    setSubjects(data.subjects);
    setValue("name", data.name);
    setValue("no_of_days", data.no_of_days);
    setValue("hrs_class", PackageMinOptions.find((x) => x.value == data.hrs_class));
    setValue("amount", data.amount);
    setValue("descriptions", data.descriptions);
  };

  // Fetch Package Details
  const {
    sendRequest: fetchPckgDtls,
    isLoading: pckgLoading,
    error: pckgError,
  } = useHttp(handlePckgDetails);

  // useEffect
  useEffect(() => {
    if (id) {
      fetchPckgDtls({ url: `${getPackages}/${id}` });
    }
    fetchSubjects({ url: getSubjects });
  }, [fetchSubjects, id]);

  return (
    <div className="max-w-screen-sm w-full m-auto relative">
      {showAlert && removeObje && (
        <AlertConfirm
          open={showAlert}
          title={`Delete ${removeObje.subject_name} from this Package`}
          confirm={initDelete}
          cancel={onCancel}
          isLoading={delSubEntry}
        />
      )}
      {pckgLoading && <Loader />}
      <form onSubmit={handleSubmit(packageSubmit)}>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div>
            <label htmlFor="packagename" className="package-label">
              Package Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm relative">
              <input
                type="text"
                name="name"
                id="packagename"
                className={`package-inp ${errors.name && "inp-error"}`}
                placeholder="Package Name"
                {...register("name", { required:  "Field is Required" })}
              />
            </div>
            <ErrorMessage errors={errors} name="name" render={({ message }) => <span className="err-msg">{message}</span>} />
          </div>
          <div>
            <label htmlFor="totaldays" className="package-label">
              No. of Days
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="number"
                name="no_of_days"
                id="totaldays"
                min={0}
                className={`package-inp ${errors.no_of_days && "inp-error"}`}
                placeholder="Total Days of Course"
                {...register("no_of_days", {
                  required: "Days is Required",
                  valueAsNumber: "Enter a Valid Number",
                })}
              />
            </div>
            <ErrorMessage errors={errors} name="no_of_days" render={({ message }) => <span className="err-msg">{message}</span>} />

          </div>
          <div>
            <label htmlFor="company-website" className="package-label">
              Amount
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="number"
                id="company-website"
                className={`package-inp ${errors.amount && "inp-error"}`}
                placeholder="Amount"
                {...register("amount", { required: "Amount is Required", valueAsNumber: "Enter a valid Number" })}
              />
            </div>
            <ErrorMessage errors={errors} name="name" render={({ message }) => <span className="err-msg">{message}</span>} />

          </div>
          <div>
            <label htmlFor="hrsPerClass" className="package-label">
              Mins Per Class
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Controller control={control} name="hrs_class" render={({field: {onChange, value}, formState: {error}}) => (
            <Select
              options={PackageMinOptions}
              isMulti={false}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isSearchable={false}
              onChange={onChange}
              value={value}
              menuPlacement="top"
              menuPosition="fixed"
              className="w-full"
            />)} />
             
            </div>
            <ErrorMessage errors={errors} name="name" render={({ message }) => <span className="err-msg">{message}</span>} />

          </div>
          <div className="md:col-span-2 col-span-1">
            <label htmlFor="about" className="package-label">
              Description
            </label>
            <div className="mt-1">
              <textarea
                id="about"
                name="descriptions"
                rows={3}
                className="common-textarea"
                placeholder="Package description"
                {...register("descriptions", { required: false })}
              />
            </div>
          </div>
        </div>
        <div className="pt-2 mt-2 border-t-tiny border-slate-400">
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Add Subjects to this package
            </label>
            <Select
              options={subjOpt}
              isMulti={true}
              getOptionLabel={(option) => option.subject_name}
              getOptionValue={(option) => option.subject_id}
              isSearchable={false}
              onChange={handleSelChange}
              value={subjects}
              menuPlacement="top"
              menuPosition="fixed"
            />
            <span className="text-xs text-slate-400 capitalize">
              after selecting subjects, you have to add class to each subjects
              respectively.
            </span>
          </div>
          <div className="grid grid-cols-1 gap-2">
            {subjects.map((item, index) => (
              <SubjectHours subject={item} key={index} inpChange={addHours} />
            ))}
          </div>
        </div>
        <div className="py-3 text-right">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {!id ? "Save" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PackageForm;
