import React from 'react';
import { Fragment, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import { classNames } from "../helper/_helper";
import AuthContext from "../context/auth-context";
import { ConfigConst } from "../config";


const user = {
  imageUrl: `${ConfigConst.mainroutes}common-assets/avatar1.png`
};
const navigation = [
  { name: "Dashboard", href: "/" },
  { name: "Teachers", href: "/teachers" },
  { name: "Students", href: "/students" },
  { name: "Subjects", href: "/subjects" },
  { name: "Packages", href: "/packages" },
];
const userNavigation = [
  { name: "Change Password", href: "/change-password" },
];

const NavLink = ({ children, to, ...props }) => {

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link
      to={to}
      {...props}
      className={classNames(
        match
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        `px-3 py-2 rounded-md text-sm font-medium uppercase ${props.className}`
      )}
      aria-current={match ? "page" : undefined}
    >
      {children}
    </Link>
  );
};

export default function MainNav() {
  const auth = useContext(AuthContext);
  const { logo } = ConfigConst;


  const handleLogout = () => auth.logout();

  return (
    <Fragment>
      <div className="min-h-full" id="header">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <Fragment>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12"
                        src={logo}
                        alt="LearnandGrow"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <NavLink key={item.name} to={item.href}>
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Menu as="div" className="ml-3 relative">
                        <div className='flex align-center'>
                          <h4 className='m-0 mr-2 text-white medium text-sm admin-label'><span className='block normal'>Welcome</span> Admin</h4>
                          <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.imageUrl}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}

                            <Menu.Item>
                              <button
                                className="bg-red-600 text-white w-full leading-9 relative -bottom-1 rounded-b-md text-sm font-medium"
                                onClick={handleLogout}
                              >
                                Logout
                              </button>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      className="w-full block text-left"
                    >
                      <NavLink to={item.href} className="block w-full">
                        {item.name}
                      </NavLink>
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white uppercase">
                        {auth.user.name}
                      </div>
                      
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Link
                        className="text-white w-full block leading-9 text-center"
                        to={item.href}
                        key={item.name}
                      >
                        {item.name}
                      </Link>
                    ))}
                    <button
                      className="bg-red-600 text-white w-full leading-9 relative -bottom-1 rounded-b-md text-sm font-medium"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </Disclosure.Panel>
            </Fragment>
          )}
        </Disclosure>
      </div>
      
    </Fragment>
  );
}
