import React, { useContext, Fragment } from "react";
import { Outlet } from "react-router-dom";

import AuthContext from "../context/auth-context";
import Loader from "./loader";
import TeacherNav from "./teachernav";
const TeacherLayout = () => {
  const auth = useContext(AuthContext);
  const { isLoading } = auth;
  const year = new Date().getFullYear();

  return (
    <Fragment>
      <TeacherNav />
      <div className="lg:px-0 px-3 bg-slate-200 py-5 min-h-screen inline-block w-full">
        {isLoading && <Loader />}
        <Outlet />
      </div>
      <footer className="p-4 bg-white shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © {year}
          <a href="#" className="hover:underline">
            &nbsp;Learn and Grow Academy
          </a>
          . All Rights Reserved.
        </span>
      </footer>
    </Fragment>
  );
};

export default TeacherLayout;
