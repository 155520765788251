import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StudentForm from "../modules/StudentForm";
import PackageStats from "../components/student/package.trail";
import { ApiRoutes } from "../config/apiroutes";
import useHttp from "../hooks/use-http";
import AlertConfirm from "../components/confirmalert";
import { useNavigate } from "react-router-dom";

let selStudent = null;

const StudentCrud = () => {
  const { id } = useParams();

  const [packages, setPackages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const { delStudent, getPackageProgres } = ApiRoutes;

  const _handlefetchstats = (res) => {
    const { data } = res;
    setPackages(data);
  };

  const { sendRequest: fetchPackageStat, isLoading: packageProgLoad } =
    useHttp(_handlefetchstats);
  const _fetchPackageProgress = () =>
    fetchPackageStat({ url: `${getPackageProgres}/${id}` });

  useEffect(() => {
    _fetchPackageProgress();
  }, []);

  // Delete Request
  const navigate = useNavigate();
  // Handle Delet
  const handleDelete = (res) => {
    navigate("/students");
  };

  const { sendRequest: deleteStudent, isLoading: delLoading } = useHttp(handleDelete);

  const handleConfirm = () => {
    deleteStudent({ url: delStudent, method: "POST", body: selStudent });
  };



  const initDelete = (item) => {
    console.log(item);
    selStudent = item;
    setShowAlert(true);
  };

  const handleCancel = () => setShowAlert(false);

  return (
    <>
      <div className="container mx-auto mt-5">
        <div className="card max-w-2xl m-auto">
          <StudentForm id={id} onDelete={initDelete} />
          {!!id && packages.length > 0 && (
            <div className="p-5">
              {packages.map((item) => (
                <PackageStats item={item} key={item.id} />
              ))}
            </div>
          )}
        </div>
      </div>
      {showAlert && (
        <AlertConfirm
          open={showAlert}
          title={`Delete ${selStudent.name}`}
          message="Deleting can't be Reversed. Are You Sure ?"
          confirm={handleConfirm}
          isLoading={false}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default StudentCrud;
