import React from 'react';
import { useContext } from "react";
import { CogIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as Yup from "yup";

import { useLocation } from "react-router-dom";

import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config/apiroutes";

import AuthContext from "../context/auth-context";

const ChangePassword = () => {
  const formSchema = Yup.object().shape({
    currentpassword: Yup.string().required("Current Password is Required"),
    password: Yup.string()
      .required("Password is required")
      .min(3, "Password must be at 3 char long"),
    confirmPwd: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const { changePassword } = ApiRoutes;

  const auth = useContext(AuthContext);

  const location = useLocation();

  const _handleResponse = (data) => {
    if (data.code == 200) auth.logout();
  };

  const { sendRequest, isLoading, error } = useHttp(_handleResponse);

  const passwordSubmit = (data) => {
    sendRequest({ url: changePassword, method: "POST", body: data });
  };

  return (
    <div onSubmit={handleSubmit(passwordSubmit)}>
      <div className="mt-5">
        <div className="container m-auto">
          <div className="max-w-4xl m-auto">
            <div className="card p-4">
              <h2 className="w-full border-b-tiny border-b-gray-200 border-dashed pb-3">
                <CogIcon className="sm-icon" />
                Change password
              </h2>
              <form>
                <div className="mt-3">
                  <label className="package-label">Old password</label>
                  <input
                    type="password"
                    name="password"
                    className={`package-inp chng-pass-inp ${
                      errors.currentpassword && "inp-error"
                    }`}
                    placeholder="Current Password"
                    {...register("currentpassword")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="currentpassword"
                    render={({ message }) => (
                      <span className="err-msg">{message}</span>
                    )}
                  />
                </div>
                <div className="mt-3">
                  <label className="package-label">New password</label>
                  <input
                    type="password"
                    name="confirmPwd"
                    className={`package-inp chng-pass-inp ${
                      errors.password && "inp-error"
                    }`}
                    {...register("password")}
                    placeholder="New Password"
                  />
                </div>
                <div className="mt-3">
                  <label className="package-label">Confirm password</label>
                  <input
                    type="password"
                    name="confirmPwd"
                    className={`package-inp chng-pass-inp ${
                      errors.confirmPwd && "inp-error"
                    }`}
                    placeholder="Confirm Password"
                    {...register("confirmPwd")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="confirmPwd"
                    render={({ message }) => (
                      <span className="err-msg">{message}</span>
                    )}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Update Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
