import React from "react";

import StudProfile from "../studprofile";
import {
  UserAddIcon,
  UserRemoveIcon,
  PencilIcon,
  AcademicCapIcon,
  PhoneOutgoingIcon,
  EyeIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
const StudentCard = ({ student, onDelete, toggleModal }) => {
  const _initModal = () => {
    if (student.packages > 0) toggleModal(student.id);
  };

  return (
    <div className="student-card pb-16">
      <div className="stud-card-head">
        <StudProfile
          name={student.name}
          badge={student.warninig}
          message={student.message}
        />
        <div className="">
          <h6 className="text-xs text-slate-500">{student.student_id}</h6>
          <h3 className="capitalize truncate whitespace-nowrap w-44" title={student.name}>
            {student.name}
          </h3>
        </div>
        <a
          className="ml-auto inline-block"
          href={`tel:${student.phone_no}`}
          title="Call Now"
        >
          <PhoneOutgoingIcon className="w-4 h-4 fill-blue-600" />
        </a>
      </div>

      <div
        className={`info-incomplete ${
          student.warninig ? "opacity-1" : "opacity-0 h-5"
        }`}
      >
        <p>{student.message}</p>
      </div>
      <button
        className={`bg-purple-300 text-center text-sm py-2 px-4 relative inline-block w-full ${
          student.packages > 0 ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={_initModal}
      >
        {student.packages}
        <span className="text-xs ml-0.5">
          {student.packages > 1 ? `Packages` : "Package"}
        </span>
        {student.packages > 0 && (
          <span className="progress-modal-toggle">View Progress</span>
        )}
      </button>
      <div className="action-panel absolute bottom-0 w-full">
        <ul className="grid grid-cols-3 gap-1">
          <li>
            <Link to={`/student/${student.id}`}>
              <UserIcon className="action-icon fill-indigo-600" />
              <h5 className="text-smaller">Profile</h5>
            </Link>
          </li>
          <li>
            <Link to={`/assign-tutor/${student.id}`}>
              <UserAddIcon className="action-icon" />
              <h5 className="text-smaller">Assign Tr.</h5>
            </Link>
          </li>
          <li>
            <Link to={`/student-package/${student.id}`}>
              <AcademicCapIcon className="action-icon fill-purple-700" />
              <h5 className="text-smaller">Packages</h5>
            </Link>
          </li>
          {/* <li>
            <button onClick={onDelete.bind(this, student)}>
              <UserRemoveIcon className="action-icon fill-red-600" />
              <h5 className="text-smaller">Delete</h5>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default StudentCard;
