import React, { useState } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import TeacherForm from "../modules/TeacherForm";
import {
  TrashIcon,
  PencilIcon,
  CurrencyRupeeIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/outline";
import useHttp from "../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "../config";
import AlertConfirm from "../components/confirmalert";


const TeacherProfile = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [showAlert, setShowAlert] = useState(false);

  const { delTeacher } = ApiRoutes;
  const navigate = useNavigate();

  const handleAfterDel = () => {
    setShowAlert(false);
    navigate("/teachers", { replace: true });
  };

  const handleConfirm = () =>
    delTutor({ url: delTeacher, method: "POST", body: { id: id } });

  const { sendRequest: delTutor, error, isLoading } = useHttp(handleAfterDel);

  const initDel = () => {
    setShowAlert(true);
  };

  const handleCancel = () => setShowAlert(false);

  return (
    <>
      <div className="container m-auto">
        <div className="bg-white rounded-md shadow-sm pt-4 pb-0 max-w-3xl mt-5 mx-auto w-full">
          <div className="w-full m-auto border-b-tiny border-gray-300 mb-0 px-4 flex flex-row items-center pb-2">
            <h3>Teacher Profile</h3>
            <div className="action-panel flex ml-auto">
              <Link
                to={`/tutor-edit/${id}`}
                state={{ from: `/teacher-profile/${id}` }}
                className="mr-3 bg-blue-600 round-btn flex items-center inline-block"
                title="Edit"
              >
                <PencilIcon className="w-4 h-4 m-auto stroke-white" />
              </Link>
              <button
                className="round-btn bg-red-700 "
                title="Delete"
                onClick={initDel}
              >
                <TrashIcon className="w-4 h-4 m-auto stroke-white" />
              </button>
            </div>
          </div>
          <TeacherForm id={id} from={state ? state.from : '/teachers'} viewOnly />
        </div>
      </div>
      {showAlert && (
        <AlertConfirm
          open={showAlert}
          title={`Delete`}
          message="Deleting can't be Reversed. Are You Sure ?"
          isLoading={isLoading}
          confirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default TeacherProfile;
