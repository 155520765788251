import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useHttp from "../../hooks/use-http";
import { ApiRoutes } from "../../config";
import { toast } from "react-toastify";
import PasswordGenerator from "../../helper/passwordgen";
const CredeUpdate = ({ teacher, onSucessSubmission }) => {
  const { register, setValue, handleSubmit } = useForm();

  const { id, trId, password } = teacher;

  const { changeTeacherPasswordAdmin } = ApiRoutes;

  useEffect(() => {
    setValue("username", trId);
    setValue("password", password);
  }, []);

  const generatePassword = () => {
    const password = PasswordGenerator();
    setValue("password", password);
  };

  const _handleSendReq = (res) => {
    if (res.status == "success") {
      onSucessSubmission();
      toast(res.message);
    }
  };

  const { sendRequest } = useHttp(_handleSendReq);

  const _handleCredUpdate = (data) => {
    sendRequest({
      url: changeTeacherPasswordAdmin,
      method: "POST",
      body: { ...data, id },
    });
  };
  return (
    <form onSubmit={handleSubmit(_handleCredUpdate)}>
      <div className="mb-2">
        <label className="package-label">Login Id</label>
        <input
          type="text"
          className="package-inp"
          {...register("username", {})}
        />
      </div>
      <div>
        <label className="package-label">Password</label>
        <div className="relative">
          <input
            type="text"
            className="package-inp"
            {...register("password", {})}
          />
          <button
            type="button"
            onClick={generatePassword}
            className="absolute w-fit px-3 rounded-r-md  bottom-0 right-0 text-xs lg:h-full h-9 bg-cyan-700 text-white"
          >
            Generate Password
          </button>
        </div>
      </div>
      <button className="mt-3 inline-block bg-green-500 text-white rounded-sm px-5 leading-9 h-9 text-sm font-medium">
        Update
      </button>
    </form>
  );
};

export default CredeUpdate;
