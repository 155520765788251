import React , { Fragment, useState } from "react";
import { PencilIcon } from "@heroicons/react/outline";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import StudentTeachers from "./student.teacher";
import { Menu, Transition } from "@headlessui/react";
import AlertConfirm from "../confirmalert";
const StudPackage = (props) => {
  const { studPackage } = props;

  const [confirmAlert, setConfirmAlert] = useState(false);

  const [cancelAlert, setCancelAlert] = useState(false);

  const fromDate = new Date(studPackage.start_date).toLocaleDateString();
  const endDate = new Date(studPackage.end_date).toLocaleDateString();

  const _initConfirmation = () => setConfirmAlert(true);

  const _initCancelConfirmation = () => setCancelAlert(true);

  const handleChange = (e) => {
    props.onStatusChange({ id: studPackage.id, status: e });
    setConfirmAlert(false);
    setCancelAlert(false);
  };

  return (
    <Fragment>
      <div className="package-card pb-10 overflow-hidden">
        <div className="absolute right-20 top-4" title="More Actiion">
          <Menu as="div" className="relative inline-block text-left">
            {studPackage.status != 2 && studPackage.status != 0 && (
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md text-sm font-medium text-black hover:bg-opacity-30">
                  <DotsVerticalIcon className="sm-icon mr-auto" />
                </Menu.Button>
              </div>
            )}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                <div className="overflow-hidden rounded-md">
                  <Menu.Item>
                    <button
                      onClick={_initConfirmation}
                      className={
                        "bg-green-500 text-white w-full leading-10 text-sm"
                      }
                    >
                      Mark as Complete
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={_initCancelConfirmation}
                      className={
                        "bg-red-500 text-white w-full leading-10 text-sm"
                      }
                    >
                      Cancel Package
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="days-badge">
          <span className="font-medium text-white">
            {studPackage.no_of_days}
          </span>
          <span className="text-xs relative bottom-1">Days</span>
        </div>
        <div className="py-3 px-5 mb-3">
          <h4 className="text-gray-400 text-sm">Package</h4>
          <h3 className="font-medium text-lg">{studPackage.package_name}</h3>
        </div>
        <div className="flex justify-end h-9 items-center mb-5">
          <div
            className={`absolute text-sm left-0 px-3 h-9 leading-9 rounded-r-2xl text-white font-medium`}
            style={{ background: studPackage.status_class }}
          >
            {studPackage.status_message}
          </div>
          <div className="mr-5 text-sm font-medium text-indigo-700">
            {fromDate} - {endDate}
            <button className="ml-1" onClick={props.onExpChange}>
              <PencilIcon className="w-4 h-4 text-purple-500 relative" />
            </button>
          </div>
        </div>
        <div className="px-4">
          <h4 className="text-xs mb-3">Assigned Teachers</h4>
          {studPackage.subjects.map((item, index) => (
            <StudentTeachers
              subjects={item}
              totalHrs={item.no_of_hrs}
              key={index}
            />
          ))}
        </div>
      </div>
      {confirmAlert && (
        <AlertConfirm
          open={true}
          title={`Sure want to Mark ${studPackage.package_name} as Completed !`}
          message={"Are you sure want to confirm ?"}
          success={true}
          confirmBtnTxt={"Yes , Sure !"}
          cancelBtnTxt={"No, Cancel"}
          onCancel={() => setConfirmAlert(false)}
          confirm={handleChange.bind(this, 2)}
        />
      )}

      {cancelAlert && (
        <AlertConfirm
          open={true}
          title={`Sure want to Mark ${studPackage.package_name} as Cancelled !`}
          message={"Are you sure want to confirm ?"}
          confirmBtnTxt={"Yes , Sure !"}
          cancelBtnTxt={"No"}
          onCancel={() => setCancelAlert(false)}
          confirm={handleChange.bind(this, 0)}
        />
      )}
    </Fragment>
  );
};

export default StudPackage;
