import React, { useEffect, useState } from "react";
import Select from "react-select";
import useHttp from "../../hooks/use-http";
import { hrsOptions, minsOptions } from "../../helper/_helper";
import { ApiRoutes } from "../../config";

let today = "".concat(new Date().toISOString().split("T")[0]);

const LedgerForm = ({ assignedStudents, onFormSubmit, studentOpt }) => {
  const { getAssignedSubjStudentId } = ApiRoutes;

  const [selectedStud, setSelctedStud] = useState(studentOpt.studentSel);

  const [selectedSubj, setSelectedSubj] = useState(null);

  const [assignedSubj, setAssignedSubj] = useState([...studentOpt.subjectSel]);

  const [hrs, setHrs] = useState(null);

  const [mins, setMins] = useState(null);

  const [taughtDate, setTaughtDate] = useState(today);

  const _handleChangeHrs = (hrs) => setHrs(hrs.value);

  const _handleChangeSec = (seconds) => setMins(seconds.value);

  const _selectPackageSubj = (ledgeSub) => {
    setSelectedSubj(ledgeSub);
  };

  const handleLedgerSubmit = (e) => {
    e.preventDefault();
    const {id, package_id} = selectedSubj;
    let submitedData = {
      student_id: selectedStud.id,
      subject_id: id,
      no_of_hrs: hrs,
      package_id: package_id,
      taught_date: new Date(taughtDate).toISOString().split('T')[0],
    };
    onFormSubmit(submitedData);
  };

  // Fetch Subjects for Students
  const _fetchStudSubjects = (subjects) => {
    setAssignedSubj(subjects.data);
    if (subjects.data.length === 1) setSelectedSubj(subjects.data[0]);
  };

  const _handleStudPick = (student) => {
    setSelctedStud(student);
    setAssignedSubj([]);
    fetchDepSubjects({ url: `${getAssignedSubjStudentId}/${student.id}` });
  };

  const handleChange = (event) => setTaughtDate(event.target.valueAsDate);

  const { sendRequest: fetchDepSubjects, isLoading } =
    useHttp(_fetchStudSubjects);

  useEffect(() => {
    if (studentOpt.subjectSel.length == 1)
      setSelectedSubj(studentOpt.subjectSel[0]);
  }, [studentOpt]);

  return (
    <form onSubmit={handleLedgerSubmit}>
      <div className="grid gap-3 grid-cols-2">
        <div className="col-span-2">
          <h4>Select Student</h4>
          <Select
            options={assignedStudents}
            isSearchable={true}
            className="w-full inline-block mt-1"
            onChange={_handleStudPick}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            value={selectedStud}
            isDisabled={studentOpt.studentSel != null}
          />
        </div>
        <div className="col-span-2">
          <h4>Select Subjects</h4>
          <Select
            options={assignedSubj}
            isSearchable={false}
            className="w-full inline-block mt-1"
            isDisabled={assignedSubj.length < 0}
            isLoading={isLoading}
            getOptionLabel={(option) =>
              option.name + " - " + option.package_name
            }
            getOptionValue={(option) => option.id}
            onChange={_selectPackageSubj}
            value={selectedSubj}
          />
        </div>
        <div className="col-span-2">
          <h4>Pick Date</h4>
          <input
            type="date"
            className="common-inp mt-1"
            onChange={handleChange}
            defaultValue={today}
            max={today}
          />
        </div>
        <div className="col-span-2">
          <h4 className="mb-1">No of Class</h4>
          <div className="grid grid-cols-1">
            <div>
              <Select
                options={hrsOptions}
                isSearchable={true}
                className="w-full inline-block"
                placeholder="Class"
                menuPlacement="top"
                maxMenuHeight="200px"
                onChange={_handleChangeHrs}
              />
            </div>
            <div className="hidden">
              <Select
                options={minsOptions}
                isSearchable={true}
                className="w-full inline-block"
                placeholder="Mins"
                menuPlacement="top"
                onChange={_handleChangeSec}
              />
            </div>
          </div>
        </div>
        <div className="col-span-2 text-right mt-3">
          <button
            className="bg-indigo-700 hover:bg-indigo-800 text-white rounded-md md:w-40 w-full leading-9 h-9 transition-all"
            disabled={
              hrs == null || selectedStud == null || selectedSubj == null
            }
          >
            Save Ledger
          </button>
        </div>
      </div>
    </form>
  );
};

export default LedgerForm;
