import React , { useState, useEffect, Fragment } from "react";
import AssignClass from "./assignclass";
import Select from "react-select";
import { toast } from "react-toastify";
import useHttp from "../../hooks/use-http";
import { ApiRoutes } from "../../config/apiroutes";
import {useLocation, useNavigate} from "react-router-dom";

let crudUrl = "";
let hrsAdded = "";
const AssignForm = ({
  selectedOptions,
  teachersList,
  totalHrs,
  packageId,
  studentId,
  subjectId,
}) => {
  const [teachers, setTeachers] = useState(selectedOptions);

  const [isUpdated, setIsUpdated] = useState(false);

  const [error, setError] = useState(false);

  const [isBackButtonClicked, setBackbuttonPress] = useState(false);

  const { addStudentTeacher, editStudentTeacher, deleteStudentTeacher } =
    ApiRoutes;

  const location = useLocation();
  const navigate = useNavigate();

  const handleTeacherChange = (data, event) => {
    setTeachers(data);
    if (event.action == "remove-value" && event.removedValue.id > 0) {
      deleteRequest({
        url: deleteStudentTeacher,
        method: "POST",
        body: { id: event.removedValue.id },
      });
    }
  };

  const { sendRequest: deleteRequest } = useHttp(() => {});

  const handleTeacherHrs = (data) => {
    const updIndx = teachers.findIndex((x) => x.teacher_id == data.teacher_id);
    teachers[updIndx].no_of_hrs = data.no_of_hrs;
    if (data.id == undefined) {
      teachers[updIndx].id = 0;
    }
    hrsAdded = teachers.reduce(function (accumulator, curValue) {
      return accumulator + +curValue.no_of_hrs;
    }, 0);
    if (hrsAdded > totalHrs) {
      setError(true);
    } else {
      toast.dismiss()
      setIsUpdated(true);
      setError(false);
    }
  };

  useEffect(() => {
    if (selectedOptions.length > 0) {
      crudUrl = editStudentTeacher;
    } else {
      crudUrl = addStudentTeacher;
    }

    const unloadCallback = (event) => {
      event.preventDefault();
      const message =
        "Un Saved Changes Detected. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };
    if (isUpdated) {
      window.addEventListener("beforeunload", unloadCallback);
      window.addEventListener("popstate", onBackButtonEvent);
    }
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [selectedOptions, isUpdated]);


  useEffect(() => {
    if(error){
      toast.error("Total Class Exceed", {
        autoClose: false
      });
    }else{
      toast.dismiss()
    }
  }, [error])

  const _handleAssign = (response) => {
    const { data } = response;
    setTeachers(data);
  };

  const { sendRequest } = useHttp(_handleAssign);

  const _sendAssign = () => {
    let teacherAssignObj = {
      student_subject_id: subjectId,
      student_id: studentId,
      package_id: packageId,
      teachers: teachers
    };
    hrsAdded = teachers.reduce(function (accumulator, curValue) {
      return accumulator + +curValue.no_of_hrs;
    }, 0);
    if (hrsAdded > totalHrs) {
      toast.error("Total Class Exceed");
    } else {
      sendRequest({ url: crudUrl, method: "POST", body: teacherAssignObj });
      setIsUpdated(false);
    }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      if (window.confirm("Un Saved Changes Detected. Are you sure you want to leave?")) {
        setBackbuttonPress(true);
        navigate('/students', {replace: true});
      } else {
        window.history.pushState(null, null, location.pathname);
        setBackbuttonPress(false);
      }
    }
  };

  return (
    <Fragment>
      <Select
        onChange={handleTeacherChange}
        options={teachersList}
        isMulti={true}
        isSearchable={true}
        hideSelectedOptions={true}
        value={teachers}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.teacher_id}
        noOptionsMessage={() => "No Teachers Found for Subject"}
      />
      {teachers.map((teacher, index) => (
        <AssignClass
          course={teacher}
          key={index}
          subject_hrs={totalHrs}
          onAssignHrs={handleTeacherHrs}
        />
      ))}
      <div className="flex justify-end items-center mt-2">
        {isUpdated && (
          <button
            className="common-btn h-9 xs:w-full md:w-60"
            onClick={_sendAssign}
            disabled={error}
          >
            Save Changes
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default AssignForm;
