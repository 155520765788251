import React, { useState, useEffect } from "react";
import { filterOptions } from "../../helper/_helper";
import Select from "react-select";
import { ApiRoutes } from "../../config";
import LedgerCard from "./ledger.card";
import useHttp from "../../hooks/use-http";
import {
  CurrencyRupeeIcon,
  SearchIcon,
  ClockIcon,
} from "@heroicons/react/outline";
const WorkLedgers = ({ activeStudents }) => {
  // Ledger Entries Array
  const [ledgerEntries, setLedgerEntries] = useState([]);
  // Filter State
  const [selectFilter, setSelectFilter] = useState(filterOptions[0]);
  const [filterStud, setFilterStud] = useState(null);

  const [totalAmount, setTotalAmount] = useState("");

  const [totalHrs, setTotalHrs] = useState("");

  // Filter Select Change
  const _handleDurationChange = (data) => setSelectFilter(data);
  const _handleFilterStudChange = (data) => setFilterStud(data);

  // Api Endpoints
  const { getWorkLog, tutorWorkLog } = ApiRoutes;

  // Handle Response
  const _handleLedgerFetch = (response) => {
    const { data } = response;
    setLedgerEntries(data.log);
    setTotalHrs(data.total_hours);
    setTotalAmount(data.total)
  };

  // useHttp Hook
  const { sendRequest } = useHttp(_handleLedgerFetch);

  // SendRequest
  const _fetchWorkLog = () => {
    let type = selectFilter != null ? selectFilter.value : 7;
    let student_id = filterStud != null ? filterStud.id : "";
    sendRequest({
      url: tutorWorkLog,
      method: "POST",
      body: { type, student_id, id: 0 },
    });
  };

  useEffect(() => {
    _fetchWorkLog();
  }, [selectFilter, filterStud]);

  return (
    <section>
      <div className="filter_menu mb-3">
        <div className="md:w-60 w-full md:mb-0 mb-3">
          <Select
            onChange={_handleDurationChange}
            options={filterOptions}
            isMulti={false}
            isSearchable={false}
            value={selectFilter}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            className="filter_sel"
          />
        </div>
        <div className="md:w-60 w-full">
          <Select
            onChange={_handleFilterStudChange}
            options={activeStudents}
            isMulti={false}
            isSearchable={false}
            isClearable={true}
            value={filterStud}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            className="filter_sel"
            placeholder="Select Student"
          />
        </div>
      </div>
      {ledgerEntries.length > 0 && (
        <div className="filter_result mb-2 justify-center">
          <h4 className="text-sm hidden">Amount for the {selectFilter.label}</h4>
          <div className="flex items-center">
            <h3 className="font-medium hidden">
              Total Classes. <ClockIcon className="sm-icon" />{" "}
              {Math.trunc(totalHrs)} <small>Class.</small>
            </h3>
            <h3 className="font-medium">
              Balance <small>INR</small> {totalAmount}{" "}
              
            </h3>
          </div>
        </div>
      )}
      {ledgerEntries.map((ledger, index) => (
        <LedgerCard ledger={ledger} key={index} />
      ))}
    </section>
  );
};

export default WorkLedgers;
