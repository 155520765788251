import React, { useEffect, useState, useCallback, Fragment } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { ApiRoutes } from "../config";
import useHttp from "../hooks/use-http";
import TutorCard from "../components/tutor/tutorcard";
import AlertConfirm from "../components/confirmalert";
import FilterPanel from "../components/filter.panel";
import NoData from "../components/nodata";
import Modal from "../components/modal";
import CredeUpdate from "../components/tutor/cred.update";
import LoadGrid from "../components/loadgrid";
let selTut = null;

let searchTerm = "";

let newRequest = true;

let pager = 0;
let requestOn = false;
const TeachersList = () => {
  const [tutors, setTutors] = useState([]);

  const [count, setCount] = useState(0);

  const [showAlert, setShowAlert] = useState(false);

  const [filterKey, setFilterKey] = useState(null);

  const { delTeacher, searchApi } = ApiRoutes;

  const [credentialModal, setCredentialModal] = useState(false);

  const [credUpdTeacher, setCredUpdTeacher] = useState(null);

  const handleRepsonse = (res) => {
    const { data, count, nextPage } = res;
    setCount(count);
    pager = nextPage;
    if (newRequest) {
      setTutors(data);
    } else {
      setTutors((prevTutors) => {
        return prevTutors.concat(data);
      });
    }
    requestOn = false;
  };
  const { sendRequest: fetchTutors, isLoading: fetchLoading } =
    useHttp(handleRepsonse);

  const _handleTeacherDelete = () => {
    const { id } = selTut;
    setTutors((current) => current.filter((x) => x.id != id));
    setCount((currentCount) => currentCount - 1);
    setShowAlert(false);
  };

  const { sendRequest: delTutor, isLoading } = useHttp(_handleTeacherDelete);

  const initDel = (data) => {
    selTut = data;
    setShowAlert(true);
  };

  const handleConfirm = () =>
    delTutor({ url: delTeacher, method: "POST", body: selTut });

  const handleCancel = () => setShowAlert(false);

  const _handleSearch = (search) => {
    const { result, count, nextPage } = search;
    searchTerm = search.searchTerm;
    pager = nextPage;
    setTutors(result);
    setCount(count);
  };
  // Reset Search
  const _handleResetSearch = (filterParms) => {
    newRequest = true;
    searchTerm = "";
    fetchTutors({
      url: searchApi,
      method: "POST",
      body: { type: "teacher", ...filterParms },
    });
  }


  // Reset Filter
  const _handleFilterReset = (filterParms) => {
    newRequest = true;
    setFilterKey(null);
    fetchTutors({
      url: searchApi,
      method: "POST",
      body: { type: "teacher", ...filterParms, search: searchTerm },
    });
  }

  // From Filter Panel
  const selectedFiltr = (filterParms) => {
    setFilterKey(filterParms);
    newRequest = true;
    fetchTutors({
      url: searchApi,
      method: "POST",
      body: { ...filterParms, search: searchTerm, type: "teacher" },
    });
  };

  // Fetch Load More data
  const _initLoadReq = useCallback(() => {
    newRequest = false;
    console.log(filterKey);
    if (pager != null && pager > 0 && !requestOn) {
      requestOn = true;
      fetchTutors({
        url: `${searchApi}/${pager}`,
        method: "POST",
        body: { ...filterKey, search: searchTerm, type: "teacher" },
      });
    }
  }, [searchApi, filterKey, fetchTutors]);

  // Credentials Update
  const _initCredModal = (teacher) => {
    setCredUpdTeacher(teacher);
    setCredentialModal(true);
  };
  const _closeModal = () => setCredentialModal(false);

  const _fetchNewTeacher = () => {
    setCredentialModal(false);
    fetchTutors({
      url: searchApi,
      method: "POST",
      body: { type: "teacher" },
    });
  };

  // Scroll to fetch more
  const _handleScroll = useCallback(
    (e) => {
      let { scrollTop, offsetHeight } = document.documentElement;
      let { innerHeight } = window;
      const bottomOfWindow =
        Math.round(scrollTop) + innerHeight === offsetHeight;
      if (bottomOfWindow) _initLoadReq();
    },
    [_initLoadReq]
  );

  useEffect(() => {
    if (filterKey === null) {
      fetchTutors({
        url: searchApi,
        method: "POST",
        body: { type: "teacher" },
      });
    }
    document.addEventListener("scroll", _handleScroll, true);
    return () => {
      document.removeEventListener("scroll", _handleScroll, true);
    };
  }, [_handleScroll, searchApi, fetchTutors, filterKey]);

  return (
    <Fragment>
      <FilterPanel
        filterFor="teacher"
        searchToggle={_handleSearch}
        resetSearchToggle={_handleResetSearch}
        totalResults={count}
        filterSelect={selectedFiltr}
        resetFilter={_handleFilterReset}
      />
      <div className="topSpacer"></div>
      {showAlert && (
        <AlertConfirm
          open={showAlert}
          title={`Delete ${selTut.name}`}
          message="Deleting can't be Reversed. Are You Sure ?"
          isLoading={isLoading}
          confirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <div className="container m-auto">
        <div className="flex justify-end md:w-1/2 w-full ml-auto md:order-2 order-1">
          <Link to="/create-tutor" title="Create Tutor" className="create_btn">
            <PlusIcon className="md:w-4 w-6 md:h-4 h-6 fill-white inline-block md:mr-2 m-auto" />
            <span className="md:inline hidden">Register New Teacher</span>
          </Link>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 mt-3 md:px-0 px-2">
          {tutors.map((tutor, index) => (
            <TutorCard
              tutor={tutor}
              key={tutor.id}
              onDelete={initDel}
              onCredUpdate={_initCredModal}
            />
          ))}
          {fetchLoading && <LoadGrid />}
        </div>
      </div>
      {tutors.length === 0 && !fetchLoading && (
        <NoData title="No Teachers Found" />
      )}
      <Modal
        isOpen={credentialModal}
        onCancel={_closeModal}
        title="Change Login Credentials"
      >
        <CredeUpdate
          teacher={credUpdTeacher}
          onSucessSubmission={_fetchNewTeacher}
        />
      </Modal>
    </Fragment>
  );
};

export default TeachersList;
