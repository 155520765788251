import React from "react";

const StatsCount = (props) => {
  return (
    <div className="stats_card">
      <span className="circle_dot left-4 -top-2 w-5 h-5"></span>
      <h4>{props.label}</h4>
      <h3>{props.count}</h3>
      <span className="circle_dot -right-8 top-4 w-20 h-20"></span>
      <figure className="stas_img">
        <img src={props.imageStat} alt={props.label} width="100" height="131" />
      </figure>
    </div>
  );
};

export default StatsCount;
