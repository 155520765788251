import React from 'react';
import { Tab } from "@headlessui/react";
import { classNames } from "../helper/_helper";
import Modal from "../components/modal";
import LedgerForm from "../components/tutor/ledger.form";
import { useState, useEffect, Fragment } from "react";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
import {
  UserGroupIcon,
  CollectionIcon,
  CashIcon,
  ClipboardCheckIcon,
  ArchiveIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";

import ActiveStudents from "../components/tutor/student.active";
import WorkLedgers from "../components/tutor/workledgers";
import PaymentHistory from "../components/tutor/paymenthisory";

let tempLedger = null;

let pickedStudent = {
  studentSel: null,
  subjectSel: [],
};

const TeacherLanding = () => {
  const [ledgerModal, setLedgerModal] = useState(false);

  const [teachStudents, setTeachStudents] = useState([]);

  const [ledgerEntries, setLedgerEntries] = useState([]);

  const [subjects, setSubjects] = useState([]);

  const [newClassEntry, setNewClassEntry] = useState(null);

  const { getTeachStudents, addTeacherLedger, teacherSubjects } = ApiRoutes;

  // Ledger Cancel
  const handleCancel = () => {
    setLedgerModal(false);
    pickedStudent = {
      studentSel: null,
      subjectSel: [],
    };
  };

  // Ledger Modal Init
  const initModal = () => setLedgerModal(true);

  // Fetch Students
  const _handleFetchStudents = (data) => setTeachStudents(data.data);

  const { sendRequest: fetchTeachStudent } = useHttp(_handleFetchStudents);

  // Add Ledger
  const _pushLedger = (ledgeData) => {
    const { data } = ledgeData;
    const newLedgerData = { ...data, type: "log" };
    setLedgerEntries((prevEntries) => [newLedgerData, ...prevEntries]);
    setLedgerModal(false);
    setNewClassEntry(data);
    toast.success("Work Log Added.");
     pickedStudent = {
      studentSel: null,
      subjectSel: [],
    };
  };

  // Send Ledger Data to Server
  const { sendRequest: sendLedgerData, error } = useHttp(_pushLedger);

  const _handleLedgerSubmit = (ledgerData) => {
    tempLedger = { ...ledgerData, id: Math.random(0, 200) };
    sendLedgerData({ url: addTeacherLedger, method: "POST", body: ledgerData });
  };

  // Handle Student Ledger
  const _handleStudentLedger = (studntData) => {
    const studentSel = {
      id: studntData.id,
      name: studntData.name,
    };
    pickedStudent = {
      studentSel,
      subjectSel: studntData.subjects,
    };
    setLedgerModal(true);
  };

  // FetchTutorSubjects
  const _handleTutorSubject = (response) => {
    const { data } = response;
    setSubjects(data);
  };
  const { sendRequest: fetchSubjects } = useHttp(_handleTutorSubject);

  useEffect(() => {
    fetchTeachStudent({ url: getTeachStudents });
    fetchSubjects({ url: teacherSubjects });
  }, [fetchTeachStudent, fetchSubjects, getTeachStudents, teacherSubjects]);



  return (
    <div className="container m-auto">
      <div className="flex md:justify-end justify-center mb-3">
        <button
          onClick={initModal}
          className="bg-purple-800 rounded-md text-white max-w-fit w-full h-10 leading-10 px-5 box-border hover:bg-purple-900 transition-all"
        >
          <ClipboardCheckIcon className="sm-icon" />
          Add Class Entry
        </button>
      </div>
      <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900 md:rounded-md justify-center md:relative fixed left-0 bottom-0 w-full z-10 rounded-none">
          <Tab
            className={({ selected }) =>
              classNames(
                "tab-list max-w-xs w-full md:text-sm text-xs",
                "tab-list-hover",
                selected ? "tab-list-active" : "tab-list-normal"
              )
            }
          >
            <UserGroupIcon className="w-4 h-4 md:inline block mx-auto md:mr-2" />
            <span className="md:inline-block hidden">Active Students</span>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "tab-list max-w-xs w-full md:text-sm text-xs",
                "tab-list-hover",
                selected ? "tab-list-active" : "tab-list-normal"
              )
            }
          >
            <CollectionIcon className="w-4 h-4 md:inline block mx-auto md:mr-2" />
            <span className="md:inline-block hidden">Work Ledger</span>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "tab-list max-w-xs w-full md:text-sm text-xs",
                "tab-list-hover",
                selected ? "tab-list-active" : "tab-list-normal"
              )
            }
          >
            <CashIcon className="w-4 h-4 md:inline block mx-auto md:mr-2" />
            <span className="md:inline-block hidden">Payment History</span>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "tab-list max-w-xs w-full md:text-sm text-xs",
                "tab-list-hover",
                selected ? "tab-list-active" : "tab-list-normal"
              )
            }
          >
            <ArchiveIcon className="w-4 h-4 md:inline block mx-auto md:mr-2" />
            <span className="md:inline-block hidden">Archieved Students</span>
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>
            <Fragment>
              <h5 className="md:hidden block text-lg font-medium text-center mb-3">
                Active Students
              </h5>
              <ActiveStudents
                addClassEntry={_handleStudentLedger}
                subjects={subjects}
                newEntry={newClassEntry}
                resetNewEntry={() => setNewClassEntry(null)}
              />
            </Fragment>
          </Tab.Panel>
          <Tab.Panel className={classNames("tab-panel", "tab-panel-active")}>
            <Fragment>
              <h5 className="md:hidden block text-lg font-medium text-center mb-3">
                Work Ledger
              </h5>

              <WorkLedgers
                ledgers={ledgerEntries}
                activeStudents={teachStudents}
              />
            </Fragment>
          </Tab.Panel>
          <Tab.Panel className={classNames("tab-panel", "tab-panel-active")}>
            <Fragment>
              <h5 className="md:hidden block text-lg font-medium text-center mb-3">
                Payment History
              </h5>
              <PaymentHistory />
            </Fragment>
          </Tab.Panel>
          <Tab.Panel className={classNames("tab-panel", "tab-panel-active")}>
            <Fragment>
              <h5 className="md:hidden block text-lg font-medium text-center mb-3">
                Archieved Students
              </h5>
              <ActiveStudents
                addClassEntry={() => {}}
                archeive={true}
                resetNewEntry={() => {}}
              />
            </Fragment>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <Modal isOpen={ledgerModal} onCancel={handleCancel}>
        <div className="h-96 flex flex-col justify-center">
          <LedgerForm
            assignedStudents={teachStudents}
            onFormSubmit={_handleLedgerSubmit}
            studentOpt={pickedStudent}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TeacherLanding;
