import React from 'react';
import { useParams, useLocation } from "react-router-dom";
import TeacherForm from "../modules/TeacherForm";
const TeacherCrud = () => {
  const { id } = useParams();
  const { state } = useLocation();
  return (
    <div className="container m-auto">
      <div className="bg-white rounded-md shadow-sm pt-4 pb-0 max-w-3xl mt-5 mx-auto w-full">
        <div className="w-full m-auto border-b-tiny border-gray-300 mb-5 px-4">
          <h3 className="mb-3">{!id ? 'Create Teacher' : "Edit Teacher"}</h3>
        </div>
        <TeacherForm id={id} from={state ? state.from : '/teachers'} />
      </div>
    </div>
  );
};

export default TeacherCrud;
