import React from "react";

import { BellIcon } from "@heroicons/react/solid";
const StudProfile = ({ name, badge, message }) => {
  const smNam = name.substring(0, 2);
  return (
    <div className="rounded-full w-10 h-10 flex justify-center items-center mr-3 lg-random relative bg-teal-700">
      {badge && (
        <a
          className="rounded-full w-5 h-5 cursor-pointer bg-red-700 absolute flex items-center justify-center -top-1 left-0 shadow"
          title={message}
        >
          <BellIcon className="w-3 h-3 fill-white" />
        </a>
      )}
      <h6 className="text-white font-medium capitalize">{smNam}</h6>
    </div>
  );
};

export default StudProfile;
