import React, { Fragment } from "react";
import {
  CalendarIcon,
  InformationCircleIcon,
  ClockIcon,
  CurrencyRupeeIcon,
  AcademicCapIcon
} from "@heroicons/react/outline";
import { FormatDate } from "../../helper";
const LedgerCard = ({ ledger }) => {
  return (
    <div className={`ledgerCard ${ledger.type} ${ledger.status}`}>
      <div className="ledgerInner">
        {ledger.type == "log" ? (
          <Fragment>
            <div className="md:relative absolute md:top-0 top-1">
              <h5>
                <span>
                  <CalendarIcon className="sm-icon mr-1 w-4 h-4 relative -top-0.5" />
                </span>
                <FormatDate>{ledger.taught_date}</FormatDate>
              </h5>
            </div>
            <div className="ledgerRemarks">
              <h5 className="capitalize">
                {ledger.student} / {ledger.subject} / {ledger.package}
              </h5>
            </div>
            <div className="ml-auto md:relative absolute md:top-0 top-1 right-0 hrs-span">
              <h5>
                <span>
                  <AcademicCapIcon className="sm-icon mr-1 w-4 h-4 relative -top-0.5" />
                </span>
                ({ledger.no_of_hrs} <small>Class</small>) &nbsp;
                <small>INR.</small>{ledger.amount} 
              </h5>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="md:relative absolute md:top-0 top-1">
              <h5>
                <span>
                  <CalendarIcon className="sm-icon mr-1 w-4 h-4 relative -top-0.5" />
                </span>{" "}
                <FormatDate>{ledger.taught_date}</FormatDate>
              </h5>
            </div>
            <div className="ledgerRemarks">
              <h5>
                <span>
                  <InformationCircleIcon className="sm-icon mr-1 w-4 h-4 relative -top-0.5" />
                </span>
                {ledger.status === 'Initiated'
                  ? `(Payment ${ledger.status} on ${new Date(ledger.created_at).toLocaleDateString()} )`
                  : `(Initiated on ${new Date(ledger.created_at).toLocaleDateString()} ${ledger.status} on ${new Date(ledger.updated_at).toLocaleDateString()})`
                }
              </h5>
            </div>
            <div className="ml-auto md:relative absolute md:top-0 top-1 right-0 hrs-span">
              <h5>
                <span>
                  <CurrencyRupeeIcon className="sm-icon mr-1 w-4 h-4 relative -top-0.5" />
                </span>
                <small>INR.</small> {ledger.amount} 
              </h5>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default LedgerCard;
