import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
import { XIcon } from "@heroicons/react/outline";
const SearchForm = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { searchApi } = ApiRoutes;

  const _handleSearchResults = (searchres) => {
    let searchTerm = getValues("search");
    props.afterSearch({result: searchres.data, searchTerm, count: searchres.count, nextPage: searchres.nextPage});
  };
  
  const _resetSearch = () => {
    props.onSearchReset();
    setValue("search", "");
  }

  const { sendRequest: postSearch } = useHttp(_handleSearchResults);

  const { searchFor } = props;

  const handleSearch = (data) => {
    if(!!data.search){
      postSearch({
        url: searchApi,
        method: "POST",
        body: { ...data, type: searchFor, filterBy: props.filterByVal, filterWith: props.filterWithVal },
      });
    }else{
      _resetSearch();
    }
   
  };
  
  return (
    <form onSubmit={handleSubmit(handleSearch)} className="md:w-auto w-full">
      <div className="flex justify-center">
        <div className="w-full">
          <div className="input-group relative flex flex-wrap items-stretch w-full rounded ">
            <input
              type="text"
              className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Id / Name / Mobile"
              aria-label="Search"
              aria-describedby="button-addon2"
              {...register("search", { required: false })}
            />
            {!!getValues("search") && (
              <span onClick={_resetSearch} className="cursor-pointer">
                <XIcon className="w-5 h-5 absolute right-10 top-2.5" />
              </span>
            )}
            <button
              className="input-group-text flex items-center justify-center w-7 h-7 leading-7 text-base font-normal text-white text-center whitespace-nowrap absolute right-0 top-1.5 bg-indigo-400 rounded-full"
              id="basic-addon2"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search"
                className="w-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
