import jwtDecode from 'jwt-decode';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext({
    user: {
        token: '',
        role: '',
        name: '',
        username: ''
    },
    isLoggedIn: false,
    isLoading: false,
    packageFilter: 0,
    login: (token) => {},
    logout: () => {},
    loadingInit: () => {},
    resetLoading: () => {},
    changeFilter: () => {}
});


const calculateRemainig = (token) => {
    const {exp} = jwtDecode(token);
    const expirationTime = new Date(new Date().getTime() + exp *1000);
    const currentTime = new Date().getTime();
    const adjExpires = new Date(expirationTime).getTime();
    // const remainDuration =  adjExpires - currentTime;
    const remainDuration = 6000000;
    return remainDuration;
}

export const AuthContextProvider = (props) => {
    
    const intialState = JSON.parse(localStorage.getItem('user'));

    const [authUser, setAuthUser] = useState({...intialState});

    const [isLoading, setIsLoading] = useState(false);

    const filterInit = localStorage.getItem('packageFilter') ? localStorage.getItem("packageFilter") : 0;

    const [packageFilter, setPackageFilter] = useState(filterInit);


    const userIsLoggedIn = !!authUser.token;

    const navigate = useNavigate();

    const logoutHandler = () => {
        setAuthUser({user: null});
        localStorage.clear('user');
        navigate('/login', {replace: true})
    };

    const changePackageFilter = (data) => {
        setPackageFilter(data);
        localStorage.setItem("packageFilter", data);
    }
    
    const loginHandler = (data) => {
        setAuthUser({...data});
        localStorage.setItem('user', JSON.stringify(data)); 
        const duration = calculateRemainig(data.token);
        setTimeout(logoutHandler, duration);
    };

    const loadingInit = () => setIsLoading(true);

    const resetLoading = () => setIsLoading(false);

    const contextval = {
        user: authUser,
        isLoggedIn: userIsLoggedIn,
        isLoading: isLoading,
        packageFilter,
        login: loginHandler,
        logout: logoutHandler,
        loadingInit: loadingInit,
        resetLoading: resetLoading,
        changeFilter: changePackageFilter
    }

    return <AuthContext.Provider value={contextval}>{props.children}</AuthContext.Provider>
}


export default AuthContext;
