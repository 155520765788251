import React , { useState, useContext, useCallback } from "react";
import AuthContext from "../context/auth-context";
import { ConfigConst } from "../config/constants";
import { toast } from "react-toastify";
let { server } = ConfigConst;

const useHttp = (returnData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const auth = useContext(AuthContext);
  let token = auth.user.token;

  const sendRequest = useCallback(async (reqConfig) => {
    setError(null);
    const {showLoader = true} = reqConfig;
    setIsLoading(true);
    if(showLoader){
      auth.loadingInit(true);
    }
    try {
      const response = await fetch(server + reqConfig.url , {
        method: reqConfig.method ? reqConfig.method : "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: reqConfig.body ? JSON.stringify(reqConfig.body) : null,
      });
      setIsLoading(false);
      auth.resetLoading(false);
      const data = await response.json();
      if(data.status === "error"){
        throw new Error(data.message);
      }
      returnData(data);
    } catch (err) {
      auth.resetLoading(false);
      setError(err);
      toast.error(err.message);
    }
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
