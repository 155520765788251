import React from "react";

import {
  MailIcon,
  PhoneIcon,
  ClipboardCheckIcon,
} from "@heroicons/react/solid";
import { FormatDate } from "../../helper";
import PackageProgress from "../student/package.progress";
import StudProfile from "../studprofile";

const ActiveStudentsCard = ({ student = [], onLedgerAdd, isEditable = true }) => {
  return (
    <div className="student-card">
      <div className="stud-card-head">
        <StudProfile name={student.name} />
        <div className="">
          <h6 className="text-xs text-slate-500">{student.student_id}</h6>
          <h3 className="capitalize truncate whitespace-nowrap w-32">
            {student.name}
          </h3>
        </div>
        {isEditable && (
          <button
            title="Add Class Entry"
            className="mark-ledger"
            onClick={onLedgerAdd}
          >
            <ClipboardCheckIcon className="fill-white w-5 h-5" />
          </button>
        )}
      </div>
      <div className="student-meta grid grid-cols-2">
        <div className="meta-detail flex items-center">
          <MailIcon className="sm-icon fill-black w-5 h-5" />
          <span className="text-sm text-slate-600 whitespace-nowrap truncate md:w-full w-20 inline-block">
            {student.email}
          </span>
        </div>
        <div className="meta-detail flex items-center">
          <PhoneIcon className="sm-icon fill-black w-5 h-5" />
          <span className="text-sm text-slate-600">{student.phone_no}</span>
        </div>
      </div>
      {student?.subjects.map((item) => (
        <div className="subject-progress" key={item.id}>
          <div className="subj-stats">
            <div className="subject-info">
              <div className="flex justify-between md:items-end items-center">
                <div className="md:mb-0 mb-1">
                  <h4 className="text-sm font-medium">{item.name}</h4>
                  <h6 className="text-xs text-gray-400">{item.package_name}</h6>
                </div>
                <div className="text-xs">
                  {Math.trunc(item.completedHrs)}/{item.totalHrs}
                </div>
              </div>
              <div className="prg-stake">
                <PackageProgress
                  packageStats={item.percntage}
                  completeStat={true}
                />
                <h6 className="text-xs text-right mt-1 pr-8"><FormatDate>{item.end_date}</FormatDate></h6>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ActiveStudentsCard;
