import React from "react";

import { CheckIcon } from "@heroicons/react/solid";
const PackageProgress = ({ packageStats, completeStat }) => {
  return (
    <div className={`progress-bar ${completeStat && "complete_pgr"}`}>
      <div
        className="progress-trail"
        style={{ width: packageStats + "%" }}
      ></div>
      {completeStat && (
        <div className="complete-badge">
          <CheckIcon
            className={`w-5 h-5 ${
              packageStats >= 100 ? "fill-green-600" : "fill-slate-400"
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default PackageProgress;
