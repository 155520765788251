export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


export const hrsOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

export const minsOptions = [
  { value: 0, label: "0 Mins" },
  { value: 25, label: "15 Mins" },
  { value: 50, label: "30 Mins" },
  { value: 75, label: "45 Mins" },
];


export const filterOptions = [
  { value: 10, label: "Last 10 Entries" },
  { value: 7, label: "Last 7 Days" },
  { value: 15, label: "Last 15 Days" },
  { value: 90, label: "Last 3 Months" },
  { value: 180, label: "Last 6 Months" },
  { value: 360, label: "Last 1 Year" },
];

export const isBottom = (el) => {
  return el.getBoundingClientRect().bottom <= window.innerHeight;
}


export const PackageMinOptions = [
  { value: 30, label: "30 Mins" },
  { value: 45, label: "45 Mins" },
  { value: 60, label: "60 Mins" },
  { value: 90, label: "90 Mins" },
  { value: 120, label: "120 Mins" },
  { value: 150, label: "150 Mins" },
  { value: 180, label: "180 Mins" },
];