import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TeacherRenmForm from "../modules/TeacherRenmForm";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
import TeacherRenmCard from "../modules/TeacherRenmCard";
const TeacherRenum = () => {
  const { id } = useParams();
  // Master Subjects
  const { getSubjects, teacherSubj } = ApiRoutes;
  const [masterSubjs, setMasterSubjs] = useState([]);
  const handleResSubjects = (res) => setMasterSubjs(res.data);
  const { sendRequest: fetchMasterSubjects } = useHttp(handleResSubjects);
  // Subject Salary
  const [tchrSubSal, setTchrSubSal] = useState([]);
  const handleSendData = (resp) => {
    setTchrSubSal((prevState) => prevState.concat(resp.data));
  };
  const { sendRequest: postTeacherSubj } = useHttp(handleSendData);
  const handleSubSel = (data) => {
    postTeacherSubj({
      url: teacherSubj,
      method: "POST",
      body: { ...data, teacher_id: id },
    });
  };
  useEffect(() => {
    fetchMasterSubjects({ url: getSubjects });
  }, []);
  return (
    <div className="container m-auto">
      <div className="bg-white rounded-md shadow-sm p-3 max-w-screen-lg w-full m-auto mb-4">
        <TeacherRenmForm
          teacherId={id}
          subjects={masterSubjs}
          onSave={handleSubSel}
        />
      </div>
      <div className="bg-white rounded-md shadow-sm p-3 max-w-screen-lg w-full m-auto mb-4">
        {tchrSubSal.map((item) => (
          <TeacherRenmCard
            subjects={masterSubjs}
            subjectSel={item.subject_id}
            onSave={handleSubSel}
            hrSal={item.rate_per_hr}
            tblid={item.id}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default TeacherRenum;
