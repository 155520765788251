import React , { useEffect, useState } from "react";
import useHttp from "../hooks/use-http";
import Select from "react-select";
import { ApiRoutes } from "../config";
import { useForm } from "react-hook-form";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const AssignPackage = (props) => {
  const { register, handleSubmit, formState: errors, setValue } = useForm();

  const { id } = props;

  const { editValue } = props;

  const { remainigPackageSud } = ApiRoutes;

  const [studPackages, setStudPackages] = useState([]);

  const [selPackages, setSelPackages] = useState();

  const handleSelChange = (data) => {
    setSelPackages(data);
    let { no_of_days } = data;
    var date = new Date();
    var today = new Date().toISOString().split('T')[0];
    var endDate = new Date(date.addDays(no_of_days)).toISOString().split('T')[0];
    setValue("start_date", today);
    setValue("end_date", endDate);
  };

  const _handleDtChange = (value) => {
    const newDate = value.target.valueAsDate;
    let {no_of_days} = selPackages;
    var endDate = new Date(newDate.addDays(no_of_days)).toISOString().split('T')[0];
    setValue("end_date", endDate);
  }

  const { sendRequest: fetchPackages } = useHttp((res) =>
    setStudPackages(res.data)
  );

  const assignPackage = (data) => {
    let formdata = !editValue
      ? {
          ...data,
          student_id: props.id,
          package_id: editValue ? editValue.package_id : selPackages.id,
        }
      : {
          ...data,
          id: editValue.id,
        };

    props.onDataSubmit(formdata);
  };

  const _initCancel = () => props.onCancel();

  useEffect(() => {
    fetchPackages({ url: `${remainigPackageSud}/${id}` });
    if (editValue) {
    setSelPackages(editValue);
      setValue("start_date", editValue.start_date);
      setValue("end_date", editValue.end_date);
    }
  }, [editValue]);

  return (
    <form className="w-full max-w-lg" onSubmit={handleSubmit(assignPackage)}>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
        {!editValue && (
          <div className="md:col-span-2 col-span-1 mb-3">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Select Package
            </label>
            <Select
              options={studPackages}
              isMulti={false}
              getOptionLabel={(option) =>
                option.name + "(" + option.no_of_days + " D)"
              }
              getOptionValue={(option) => option.id}
              isSearchable={false}
              onChange={handleSelChange}
              value={selPackages}
              menuPlacement="bottom"
              menuPosition="absolute"
              maxMenuHeight="120px"
            />
          </div>
        )}
        <div>
          <label
            htmlFor="start_date"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            From Date
          </label>
          <input
            type="date"
            name="start_date"
            id="start_date"
            className={`package-inp ${errors.start_date && "inp-error"}`}
            placeholder="To Date"
            {...register("start_date", { required: true, valueAsDate: true })}
            onChange={_handleDtChange}
          />
        </div>
        <div>
          <label
            htmlFor="end_date"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            To Date
          </label>
          <input
            type="date"
            name="end_date"
            id="end_date"
            className={`package-inp ${errors.end_date && "inp-error"}`}
            placeholder="To Date"
            readOnly
            {...register("end_date", { required: true, valueAsDate: true })}
          />
        </div>
      </div>

      <div className="mt-4 pb-1 flex justify-between">
        <button
          onClick={_initCancel}
          type="button"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={!selPackages && !editValue}
        >
          {editValue ? `Update` : `Save`}
        </button>
      </div>
    </form>
  );
};

export default AssignPackage;
