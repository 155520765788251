import React, { useEffect, useState, Fragment } from "react";
import Select, { components } from "react-select";
import { FilterIcon } from "@heroicons/react/solid";
import SearchForm from "../modules/SearchForm";
import Modal from "./modal";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
const studentOpt = [
  { value: "packages", label: "Package" },
  { value: "teacher", label: "Assigned to" },
  { value: "incomplete", label: "In-Complete / In Active" },
];

const teacherOpt = [
  { value: "subject", label: "Subject wise" },
  { value: "nostudents", label: "In-Complete-Profiles / No Student Assigned" },
];

const FilterPanel = ({
  filterFor,
  searchToggle,
  resetSearchToggle,
  totalResults,
  filterSelect,
  resetFilter
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FilterIcon className="w-5 h-5" />
      </components.DropdownIndicator>
    );
  };

  const filterOptions = filterFor == "student" ? studentOpt : teacherOpt;

  const [filterModal, setFilterModal] = useState(false);

  const initFilterMob = () => setFilterModal(true);

  const closeFilterModal = () => setFilterModal(false);

  const [filterBy, setFilterBy] = useState({id: '', value: ''});

  const [filterByMob, setFilterByMob] = useState(null);

  const [filterWith, setfilterWith] = useState({id: '', name: ''});

  const [depOptions, setDepOptions] = useState([]);

  const { getSubjects, getPackageSel, getActiveTeachers } = ApiRoutes;

  const _filterInitMob = () => {
    filterSelect({
      filterBy: filterBy.value,
      filterWith: filterWith.id,
      name: filterWith.name,
    });
    setFilterModal(false);
  };

  const _handleDepSel = (resp) => setDepOptions(resp.data);

  const { sendRequest: fetchDepSel, isLoading, error } = useHttp(_handleDepSel);

  const _handleFilterSelChange = (event, selaction) => {
    const { action } = selaction;
    if (action != "clear") {
      setFilterBy(event);
      setFilterByMob(event);
      if (event.value != "nostudents" && event.value != "incomplete") {
        let url = "";
        switch (event.value) {
          case "packages":
            url = getPackageSel;
            break;
          case "teacher":
            url = getActiveTeachers;
            break;
          default:
            url = getSubjects;
            break;
        }
        fetchDepSel({ url });
      } else {
        setDepOptions([]);
        filterSelect({ filterBy: event.value });
      }
    } else {
      setfilterWith({id: '', name: ''});
      resetFilter({
        filterBy: "",
        filterWith: "",
        filterLabl: "",
      });
      setFilterBy({id: '', value: ''});
      setFilterByMob(null);
      if(filterModal) setFilterModal(false);
    }
  };

  const _handleSubFiltrChange = (event, selActions) => {
    const { action } = selActions;
    if (action != "clear") {
      setfilterWith(event);
      if (!filterModal)
        filterSelect({
          filterBy: filterBy.value,
          filterWith: event.id,
          filterLabl: event.name,
        });
    }else{
      resetFilter({
        filterBy: '',
        filterWith: "",
        filterLabl: "",
      });
    }
  };

  const resetHandler = () => {
    resetSearchToggle({
      filterBy: filterBy.value,
      filterWith: filterWith.id,
      filterLabl: filterWith.name,
    });
  };

  return (
    <React.Fragment>
      <div className="filter_panel sticky top-0 z-20 shadow-sm">
        <div className="container m-auto">
          <div className="filter-content">
            <div className="md:flex items-center hidden">
              <Select
                className="w-full border-none"
                options={filterOptions}
                components={{ DropdownIndicator }}
                placeholder="Filter"
                onChange={_handleFilterSelChange}
                isClearable={true}
              />
            </div>
            <div className="md:flex items-center hidden">
              <Select
                className="w-full border-none"
                options={depOptions}
                components={{ DropdownIndicator }}
                placeholder={`Select`}
                onChange={_handleSubFiltrChange}
                isLoading={isLoading}
                isDisabled={depOptions.length == 0}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isClearable={false}
                value={filterWith}
              />
            </div>
            <div className="md:hidden flex items-center justify-center">
              <button onClick={initFilterMob}>
                <FilterIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="md:col-span-3 col-span-4">
              <SearchForm
                afterSearch={searchToggle}
                searchFor={filterFor}
                onSearchReset={resetHandler}
                filterByVal={filterBy.value}
                filterWithVal={filterWith}
              />
            </div>
            <div className="ml-auto flex items-center md:text-right text-center md:col-span-1 col-span-1">
              <h3>
                {totalResults}
                <span className="md:inline block md:text-inherit text-smaller capitalize">
                  {filterFor}s
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Filter" isOpen={filterModal} onCancel={closeFilterModal}>
        <div>
          <div className="md:flex items-center mb-4">
            <Select
              className="w-full border-none"
              components={{ DropdownIndicator }}
              placeholder="Filter"
              onChange={_handleFilterSelChange}
              options={filterOptions}
              isClearable={true}
              value={filterByMob}
            />
          </div>
          <div className="md:flex items-center mb-6">
            <Select
              className="w-full border-none"
              components={{ DropdownIndicator }}
              options={depOptions}
              placeholder={`Select`}
              onChange={_handleSubFiltrChange}
              menuPosition="absolute"
              menuPlacement="top"
              isLoading={isLoading}
              isDisabled={depOptions.length == 0}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              maxMenuHeight="100px"
              value={filterWith}
              isClearable={false}
            />
          </div>
          <div className="filter-action">
            <button
              className="inline-block w-40 text-center text-white bg-indigo-500 h-9 leading-9 rounded-md"
              onClick={_filterInitMob}
            >
              Filter
            </button>
          </div>
        </div>
      </Modal>
      <div className="container m-auto">
        {!!filterBy.value && !!filterWith.name && (
          <div className="p-2 text-slate-500 capitalize">
            Filter by : {filterBy.value}{" "}
            {!!filterWith.name ? "- " + filterWith.name : ""}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FilterPanel;
