import React, { useState } from "react";
const TutorSubjects = ({ subject, inpChange, tblId }) => {
  const [hrSal, setHrSal] = useState(subject ? subject.rate_per_hr : "");
  const handleInpChange = (e) => {
    setHrSal(e.target.value);
  };

  const handleInpBlur = (e) => {
    let changedData = {
      id: subject.id? subject.id : 0,
      rate_per_hr: e.target.value,
      subject_id: subject.subject_id,
    };
    inpChange(changedData);
  };
  return (
    <div className="md:flex w-full justify-between items-center border-b-tiny border-slate-300 pb-3 block">
      <label
        htmlFor="company-website"
        className="block text-sm font-medium text-gray-700"
      >
        Salary / Class for {subject.name}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          type="number"
          name={subject.name}
          id={subject.id}
          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full bg-gray-100 h-9 leading-9 px-3 rounded-md sm:text-sm border-gray-300"
          placeholder="Salary/Class"
          onBlur={handleInpBlur}
          min={0}
          value={hrSal}
          onChange={handleInpChange}
        />
      </div>
    </div>
  );
};

export default TutorSubjects;
