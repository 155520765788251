export const ApiRoutes = {
    loginUrl : 'login',
    getSubjects: 'subjects',
    addSubject: 'subjects-add',
    editSubject: 'subjects-edit',
    delSubject: 'subjects-delete',
    getPackages: 'packages',
    addPackage: 'packages-add',
    editPackage: 'packages-edit',
    delPackage: 'packages-delete',
    delPackageSubj: 'package-subject-delete',
    getStudents: 'students',
    createStud: 'students-add',
    editStud: 'students-edit',
    delStudent: 'students-delete',
    getTeachers: 'teachers',
    delTeacher: 'teachers-delete',
    createTchr: 'teachers-add',
    editTutor: 'teachers-edit',
    getPaymentOptions: 'payment-options',
    getStatus: 'status',
    teacherSubj:'add-teacher-subject',
    deleteTchrSubj: 'teacher-subject-delete',
    addTrPayment: 'add-teacher-payment',
    paymentHistory: 'teacher-payment-history',
    paymentStatusUpdate: 'teacherPaymentEdit',
    studentPackage: 'add-student-packages',
    editStudPack: 'edit-student-packages',
    updExpStudPack :'edit-student-packages',
    remainigPackageSud: 'student-packages',
    getTeachStudents: "get-teacher-students",
    addStudentTeacher: "add-student-teacher",
    editStudentTeacher: "edit-student-teacher",
    deleteStudentTeacher: "delete-student-teacher",
    getAssignedSubjStudentId: "get-students-subjects",
    addTeacherLedger: "add-teacher-work",
    getWorkLog: "get-work-log",
    getPaymentHistory: "teacher-payment-history",
    getActiveStudents: "get-teacher-assigned-students",
    tutorWorkLog: "get-teacher-work-log", 
    searchApi: "search", // Search this Api is also use for Global Fetch
    getPackageSel: "active-packages", // Active packages
    getActiveTeachers: "active-teachers", // Active Teachers
    getPackageProgres: "student-packages-details", // Show Package Progress on Modal
    dashboardStat: "dashboard-count", // Show Dashboard Count
    teacherSubjects: "teacher-subject", // For Teacher Landing Subject Filter - Options
    changePassword: "change-password",
    changeTeacherPasswordAdmin: "change-teacher-password",
    fetchAmountToBePaidWithinDate: "teacher-payment"
}