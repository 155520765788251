import React , { useEffect, useState, Fragment } from "react";
import AssignCard from "../components/assigncard";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const AssignTutor = () => {
  const { id } = useParams();

  const [currStud, setCurStud] = useState(null);

  const [teachers, setTeachers] = useState([]);

  const { getStudents, getTeachers } = ApiRoutes;

  const _handleAssigned = (res) => setCurStud(res.data);

  const { sendRequest, isLoading, error } = useHttp(_handleAssigned);

  const _handleFectedTutors = (res) => setTeachers(res.data);

  const { sendRequest: _fetchTutors } = useHttp(_handleFectedTutors);

  // Function to fetch student packages
  const fetchStudentsPackage = () =>
    sendRequest({ url: `${getStudents}/${id}` });

  // Fetch Teeacher for Select
  const fetchTutors = () => _fetchTutors({ url: getTeachers });

  useEffect(() => {
    fetchStudentsPackage();
    fetchTutors();
  }, [id]);


  return (
    <Fragment>
      <div className="container m-auto">
        <div className="h-3"></div>
        {currStud && (
          <div className="max-w-3xl m-auto md:px-0 px-2">
            <h2 className="text-slate-400 mb-2 text-xs inline-block w-full">
              Assign Teeacher for{" "}
              <span className="font-medium text-lg">{currStud.name}</span>
              <span className="text-xs md:float-right float-none md:inline block">
                Total Packages{" "}
                <span className="text-sm font-weight">
                  {currStud.packages.length}
                </span>
              </span>
            </h2>
            {currStud.packages.map((item, index) => (
              <AssignCard
                key={index}
                studPackage={item}
                teachersList={teachers}
                studentId={currStud.id}
              />
            ))}
            {currStud.packages.length == 0 && (
              <div className="flex justify-center items-center flex-col pt-5">
                <h5 className="mb-3 text-base text-gray-700">No Packages is assigned to this profile. Please Assign Package.</h5>
                <Link to={`/student-package/${id}`} className="h-9 leading-9 text-white bg-indigo-500 px-4 rounded-md inline-block">Assign Package</Link>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AssignTutor;
