import React , { useEffect, useState, Fragment } from "react";
import useHttp from "../../hooks/use-http";
import TransactionCard from "./transactioncard";
import { ApiRoutes } from "../../config";


const PaymentHistory = () => {
  const [paymentHis, setPaymentsHis] = useState([]);

  const [paymentStatus, setPaymentStatus] = useState([]);

  // fetch handle for payment status master
  const { sendRequest: _fetchPaymentStatus} = useHttp((data) => setPaymentStatus(data.data));

  // Fetch Handle for payment history
  const _handlePaymentHistory = (paymentHist) => setPaymentsHis(paymentHist.data);

  const { sendRequest: _fetchpaymentHistory } = useHttp(_handlePaymentHistory);

  const { getPaymentHistory, getStatus } = ApiRoutes;

  useEffect(() => {
    _fetchPaymentStatus({url: getStatus});
    _fetchpaymentHistory({ url: getPaymentHistory });
  }, []);



  return (
    <Fragment>
      {paymentHis.map((item, index) => (
        <TransactionCard
          transaction={item}
          key={item.id}
          paymentStatus={paymentStatus}
          editable={true}
        />
      ))}
    </Fragment>
  );
};

export default PaymentHistory;
