import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
const Modal = (props) => {
  const onSucess = () => props.onCancel();
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={props.onCancel}
      >
        <div className="min-h-screen px-4 text-center">
          {props.isOpen && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 opacity-50 bg-slate-900 pointer-events-none" />
            </Transition.Child>
          )}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              {props.title && (
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5"
                >
                  {props.title}
                </Dialog.Title>
              )}
              {!props.hideClose && (
                <button
                  className="absolute top-4 right-4"
                  onClick={props.onCancel}
                >
                  <XCircleIcon className="w-8 h-8 stroke-red-600" />
                </button>
              )}
              <div className="mt-2">{props.children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
