import React , { Fragment, useEffect, useState } from "react";
import GreetingComponent from "../components/greeting";
import StatsCount from "../components/statscount";
import Stats1 from "../assets/st-1.svg";
import Stats2 from "../assets/st-2.svg";
import Stats3 from "../assets/st-4.svg";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config/apiroutes";
const Dashboard = () => {
  // Dashboard
  const [dashCount, setDashCount] = useState({
    teachersCount: "",
    packagesCount: "",
    studentsCount: "",
  });
  const { dashboardStat } = ApiRoutes;
  const _handleCount = (response) => {
    const {data} = response;
    setDashCount(data);
  };
  const { sendRequest } = useHttp(_handleCount);

  useEffect(() => {
    sendRequest({ url: dashboardStat, showLoader: true });
  }, []);
  return (
    <Fragment>
      <div className="topSpacer"></div>
      <div className="container max-w-4xl mx-auto  md:px-0 px-4">
        <GreetingComponent />
        <div className="h-5"></div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
          <StatsCount imageStat={Stats1} count={dashCount.studentsCount} label="Total Active Students" />
          <StatsCount imageStat={Stats2} count={dashCount.teachersCount} label="Total Active Teachers" />
          <StatsCount imageStat={Stats3} count={dashCount.packagesCount} label="Total Packages" />
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
