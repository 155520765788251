import React, {  useState } from "react";
import { useForm } from "react-hook-form";


const AssignClass = ({ course, onAssignHrs, subject_hrs }) => {

  const [assignHrs, setAssignHrs] = useState(course.no_of_hrs ? course.no_of_hrs : '');

  const _checkHours = (event) => {
    let data = {
      no_of_hrs:event.target.value,
      id: course.id, 
      teacher_id: course.teacher_id
    } 
    setAssignHrs(event.target.value);
    onAssignHrs(data);
  };

  return (
    <div className="grid grid-cols-3 gap-3 align-middle tutor-sel-form my-2">
      <label className="common-label leading-9 col-span-2">Classes assigned to {course.name}</label>
      <div>
        <input
          type="number"
          placeholder="Total Classses"
          className="common-inp"
          onBlur={_checkHours}
          onChange={_checkHours}
          value={assignHrs}
        />
      </div>
    </div>
  );
};

export default AssignClass;
