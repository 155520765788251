import React, { Fragment, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, BellIcon } from "@heroicons/react/solid";
import AssignForm from "./tutor/assignform";
import PackageProgress from "./student/package.progress";

const AssignCard = ({ studPackage, studentId }) => {
  let packageId = studPackage.id;
  return (
    <div className="bg-white rounded-lg mb-4">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <Fragment>
            <Disclosure.Button className="flex justify-between w-full px-5 py-4 text-sm font-medium text-left text-purple-900">
              <h3>{studPackage.package_name}</h3>
              <h4 className="text-xs ml-auto">
                No of Days{" "}
                <span className="font-medium text-sm">
                  {studPackage.no_of_days}
                </span>
              </h4>
              <ChevronDownIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-purple-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-2 pb-2 text-sm text-gray-500">
              {studPackage.subjects.map((subj, index) => (
                <Disclosure
                  as="div"
                  className="mb-2"
                  key={index}
                  defaultOpen={true}
                >
                  {({ open }) => (
                    <Fragment>
                      <Disclosure.Button className="flex relative justify-between w-full px-4 pt-2 pb-5 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span>{subj.subject_name}</span>
                        <span className="ml-auto">
                          {subj.teachers.length == 0 && <span className="w-6 h-6 rounded-full inline-block bg-red-500 text-center mr-2"><BellIcon className="w-4 h-4 fill-white inline relative"/></span>}{subj.no_of_hrs} Classes</span>
                        <ChevronDownIcon
                          className={`${
                            open ? "transform rotate-180" : ""
                          } w-5 h-5 text-purple-500 transition-all`}
                        />
                       
                        <div className="absolute bottom-0 left-0 w-full max-w-full"><PackageProgress /></div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <AssignForm
                          selectedOptions={subj.teachers}
                          teachersList={subj.available_teachers}
                          totalHrs={subj.no_of_hrs}
                          studentId={studentId}
                          packageId={packageId}
                          subjectId={subj.id}
                        />
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              ))}
            </Disclosure.Panel>
          </Fragment>
        )}
      </Disclosure>
    </div>
  );
};

export default AssignCard;
