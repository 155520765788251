import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../context/auth-context";
const RequireAuth = ({ children }) => {
  let location = useLocation();
  const auth = useContext(AuthContext);
  toast.dismiss();
  if (!auth.user.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (auth.user !== null && auth.user.role != 1) {
    return (
      <Navigate to="/teacher-dashboard" state={{ from: location }} replace />
    );
  }
  return children;

};

export default RequireAuth;
