import React , { useEffect, useState, Fragment } from "react";
import { ApiRoutes } from "../../config";
import useHttp from "../../hooks/use-http";
import ActiveStudentsCard from "./activestudent.card";
import Select from "react-select";
import SearchForm from "../../modules/SearchForm";
import ShowMore from "../../components/showmore";
import NoData from "../../components/nodata";
let searchTerm = "";

const ActiveStudents = ({ subjects, addClassEntry, newEntry, archeive = false, resetNewEntry}) => {
  // Active Students
  const [activeStudents, setActiveStudents] = useState([]);
  const [offsetData, setOffsetData] = useState(null);

  const [filterSel, setFilterSel] = useState(null);
  // Api Routes
  const { searchApi } = ApiRoutes;
  // Handle Response
  const _handleResponse = (response) => {
    const { data } = response;
    setActiveStudents(data);
  };

  // http hook
  const { sendRequest, isLoading } = useHttp(_handleResponse);

  // fetch active students
  const _fetchActiveStudents = (searchTag, filterBy) => {
    sendRequest({
      url: searchApi,
      method: "POST",
      body: { type: "teachers-search", search: searchTag, filterBy },
    });
  };

  // Filter Search Events
  const _handleFilter = (data) => setFilterSel(data);

  const _handleSearch = (data) => {
    const { result, nextPage } = data;
    if (nextPage != null) {
      setActiveStudents((prevStudents) => {
        return prevStudents.concat(data);
      });
      setOffsetData(nextPage);
    } else {
      setActiveStudents(result);
    }
  };

  const _handleReset = () => {
    _fetchActiveStudents(searchTerm, archeive && "archive");
    setOffsetData(null);
  };

  // Paginations
  const _initLoadReq = () => {
    _fetchActiveStudents(searchTerm);
  };

  // Revise Total Working Hrs
  const _reviseTotalHours = (entry) => {
    const { student_id, subject_id, no_of_hrs } = entry;
    const tempArr = activeStudents;
    const editIndex = activeStudents.findIndex((x) => x.id === student_id);
    const subjIndex = tempArr[editIndex].subjects.findIndex(
      (subj) => subj.id === subject_id
    );
    const subjObj = tempArr[editIndex].subjects.find(
      (subj) => subj.id === subject_id
    );
    subjObj.completedHrs = +subjObj.completedHrs + +no_of_hrs;
    subjObj.percntage = Math.trunc(
      (subjObj.completedHrs / subjObj.totalHrs) * 100
    );
    tempArr[editIndex].subjects[subjIndex] = subjObj;
    setActiveStudents([...tempArr]);
    resetNewEntry();
  };

  useEffect(() => {
      if(archeive){
        _fetchActiveStudents("", "archive");
        return;
      }
      _fetchActiveStudents(searchTerm);
  }, []);

  useEffect(() => {
    if (newEntry != null) _reviseTotalHours(newEntry);
  }, [newEntry])

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4 flex-wrap-reverse">
        <div className="md:w-60 w-full hidden">
          <Select
            onChange={_handleFilter}
            options={[]}
            isMulti={false}
            isSearchable={false}
            hideSelectedOptions={true}
            value={filterSel}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            noOptionsMessage={() => "No Subjects"}
            placeholder="Filter"
            isClearable={true}
          />
        </div>
        <div className="md:ml-auto md:mb-0 mb-2 md:w-auto w-full">
          <SearchForm
            afterSearch={_handleSearch}
            searchFor="teachers-search"
            onSearchReset={_handleReset} 
            filterByVal={archeive ? "archive" : ""}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 grid-cols-1">
        {activeStudents.map((student) => (
          <ActiveStudentsCard
            key={student.id}
            student={student}
            onLedgerAdd={addClassEntry.bind(this, student)}
            isEditable={archeive ? false : true}
          />
        ))}
      </div>

      {activeStudents.length == 0 && !isLoading && <NoData />}

      {offsetData != null && offsetData > 0 && (
        <ShowMore toggleEvent={_initLoadReq} btnLoading={isLoading} />
      )}
    </Fragment>
  );
};

export default ActiveStudents;
