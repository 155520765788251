import React, { useState, useEffect, useCallback, Fragment } from "react";
import SubjectForm from "../modules/SubjectForm";
import Modal from "../components/modal";
import { PencilAltIcon, TrashIcon, PlusIcon } from "@heroicons/react/solid";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
import AlertConfirm from "../components/confirmalert";

let delSubj = null;

const SubjectMaster = () => {
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);
  const [subjects, setSubjects] = useState([]);
  const [selSubj, setSelSubj] = useState(null);

  const createSubject = (data, closeModal) => {
    if (selSubj) {
      let indx = subjects.findIndex((x) => x.id === data.id);
      subjects[indx].name = data.name;
      subjects[indx]["short_name"] = data["short_name"];
    } else {
      subjects.push(data);
    }
    if (closeModal) {
      setModal(false);
    }
    setSelSubj(null);
  };

  const {getSubjects, delSubject} = ApiRoutes;

  const fetchSubjects = (fetchedData) => {
    setSubjects(fetchedData.data);
  };

  const { sendRequest, error, isLoading } = useHttp(fetchSubjects);

const { sendRequest: deleteSubject, error: deletErr, isLoading: deleteLoading } = useHttp(()=> {});

  let urlConfig = {
    url:getSubjects,
  };

  useEffect(() => {
    sendRequest(urlConfig);
  }, []);

  const editSubject = (subj) => {
    setSelSubj(subj);
    setModal(true);
  };

  // init delete open alert box
  const initDelete = (subj) => {
    setAlert(true);
    delSubj = subj;
  };

  // Handle Delete Confirm
  const handleConfirm = () => {
    let deleteUrl = {
      url: delSubject,
      method: 'POST',
      body: delSubj
    }
    deleteSubject(deleteUrl);
    const newArray = subjects.filter((x) => x.id !== delSubj.id);
    setSubjects((current) => current.filter((x) => x.id !== delSubj.id));
    setAlert(false);
  }

  // Handle Cancel
  const handleCancel = () => {
    setAlert(false);
  }

  return (
    <Fragment>
      <div className="topSpacer"></div>
      <Modal onCancel={closeModal} title="Add Subject" isOpen={modal}>
        <SubjectForm onApply={createSubject} subject={selSubj} />
      </Modal>
      <AlertConfirm
        open={alert}
        title="Delete Subject"
        message="deleting subject can't be reversed. Are you sure"
        confirm={handleConfirm}
        onCancel={handleCancel}
        isLoading={deleteLoading} 
      />
      <div className="container m-auto">
        <div className="flex md:justify-end justify-center mb-4">
          <button
            onClick={openModal}
            className="bg-blue-500 rounded-lg text-white text-center px-4 h-9 leading-h-9 max-w-fit w-full "
          >
            <PlusIcon className="w-4 h-4 fill-white inline-block mr-2" />
            Add Subject
          </button>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4">
          {subjects.map((item, index) => (
            <div
              className="bg-white shadow-sm rounded-md p-3 flex flex-col"
              key={index}
            >
              <div className="mb-2">
                <h4 className="text-xs text-gray-300 font-light">Subject</h4>
                <h3 className="text-base text-gray-700 font-medium">
                  {item.name}
                </h3>
              </div>
              <div className="flex justify-between border-t-tiny border-t-gray-200 py-1 mt-auto">
                <button
                  className="sm-btn"
                  title="Edit"
                  onClick={editSubject.bind(this, item)}
                >
                  <PencilAltIcon className="fill-white w-5 h-5 " />
                </button>
                <button
                  className="sm-btn bg-red-600"
                  title="Delete"
                  onClick={initDelete.bind(this, item)}
                >
                  <TrashIcon className="fill-white w-5 h-5 " />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default SubjectMaster;
