import React, { useEffect, useState, useCallback, Fragment } from "react";
import StudentCard from "../components/student/studentcard";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config/apiroutes";
import AlertConfirm from "../components/confirmalert";
import FilterPanel from "../components/filter.panel";
import PackageStats from "../components/student/package.trail";
import { Link } from "react-router-dom";
import Modal from "../components/modal";
import { PlusIcon } from "@heroicons/react/outline";
import NoData from "../components/nodata";
import LoadGrid from "../components/loadgrid";
import { array } from "yup";
let selStudent = null;

let searchTerm = "";

let newRequest = true;
let pager = 0;
let requestOn = false;

const StudentList = () => {
  const { delStudent, getPackageProgres, searchApi } = ApiRoutes;

  const [students, setStudents] = useState([]);

  const [showAlert, setShowAlert] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [count, setCount] = useState(0);

  const [filterKey, setFilterKey] = useState(null);

  const [packageProg, setPackageProg] = useState([]);

  // Fetch Package Progress
  const _fetchPackageProgress = (response) => {
    setPackageProg(response.data);
    setIsOpen(true);
  };

  const { sendRequest: fetchPackageStat } = useHttp(_fetchPackageProgress);

  // Modal
  const packageProgModal = (student) => {
    fetchPackageStat({ url: `${getPackageProgres}/${student}` });
  };

  const closeModal = () => {
    setIsOpen(false);
    setPackageProg([]);
  };

  // Handle All Student Response
  const handleRepsonse = (res) => {
    const { data, count, nextPage } = res;
    setCount(count);
    pager = nextPage;
    if (newRequest) {
      setStudents(data);
    } else {
      setStudents((prevStudents) => {
        return prevStudents.concat(data);
      });
    }
    requestOn = false;
  };

  // Handle Delet
  const handleDelete = (res) => {
    const { id } = selStudent;
    setStudents((current) => current.filter((x) => x.id != id));
    setCount((currentCount) => currentCount - 1);
    setShowAlert(false);
  };

  const initDelete = (item) => {
    selStudent = item;
    setShowAlert(true);
  };

  const handleCancel = () => setShowAlert(false);

  // Fetch All Students
  const { sendRequest: fetchStudents, isLoading: studenLoading } =
    useHttp(handleRepsonse);

  // Handle Search
  const _handleSearch = (searchStud) => {
    const { result, count, nextPage } = searchStud;
    pager = nextPage;
    setStudents(result);
    searchTerm = searchStud.searchTerm;
    setCount(count);
  };

  // Delete Request
  const { sendRequest: deleteStudent, isLoading } = useHttp(handleDelete);

  const handleConfirm = () => {
    deleteStudent({ url: delStudent, method: "POST", body: selStudent });
  };

  // Reset Search
  const _handleResetSearch = (filterParms) => {
    newRequest = true;
    searchTerm = "";
    fetchStudents({
      url: searchApi,
      method: "POST",
      body: { ...filterParms, search: searchTerm, type: "student" },
    });
  }
   
  // Reset Filter
  const _handleFilterReset = (filterParms) => {
    newRequest = true;
    fetchStudents({
      url: searchApi,
      method: "POST",
      body: { ...filterParms, search: searchTerm, type: "student" },
    });
  }
  // Form Filter Panel
  const selectedFiltr = (filterParms) => {
    newRequest = true;
    setFilterKey(filterParms);
    fetchStudents({
      url: searchApi,
      method: "POST",
      body: { ...filterParms, search: searchTerm, type: "student" },
    });
  };

  const _initLoadReq = useCallback(() => {
    newRequest = false;
    if (pager != null && pager > 0 && !requestOn) {
      requestOn = true;
      fetchStudents({
        url: `${searchApi}/${pager}`,
        method: "POST",
        showLoader: false,
        body: { ...filterKey, search: searchTerm, type: "student" },
      });
    }
  }, [searchApi, filterKey, fetchStudents]);

  // Scroll to fetch more
  const _handleScroll = useCallback(
    (e) => {
      let { scrollTop, offsetHeight } = document.documentElement;
      let { innerHeight } = window;
      const bottomOfWindow =
        Math.round(scrollTop) + innerHeight === offsetHeight;
      if (bottomOfWindow) {
        _initLoadReq();
      }
    },
    [_initLoadReq]
  );

  useEffect(() => {
    console.log(filterKey);
    if (filterKey == null) {
      fetchStudents({
        url: searchApi,
        method: "POST",
        body: { type: "student" },
      });
    }
    document.addEventListener("scroll", _handleScroll, true);
    return () => {
      document.removeEventListener("scroll", _handleScroll, true);
    };
  }, [fetchStudents, _handleScroll, searchApi, filterKey]);

  return (
    <Fragment>
      {showAlert && (
        <AlertConfirm
          open={showAlert}
          title={`Delete ${selStudent.name}`}
          message="Deleting can't be Reversed. Are You Sure ?"
          confirm={handleConfirm}
          isLoading={isLoading}
          onCancel={handleCancel}
        />
      )}
      <FilterPanel
        filterFor="student"
        searchToggle={_handleSearch}
        resetSearchToggle={_handleResetSearch}
        totalResults={count}
        filterSelect={selectedFiltr}
        resetFilter={_handleFilterReset}
      />

      <div className="mt-5">
        <div className="container m-auto">
          <div className="flex md:justify-end justify-center mb-4">
            <Link to="/create-student" className="create_btn">
              <PlusIcon className="md:w-4 w-6 md:h-4 h-6 fill-white inline-block md:mr-2 m-auto" />
              <span className="md:inline hidden">Register New Student</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container m-auto">
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4 md:p-0 px-2">
          {students.map((item, index) => (
            <StudentCard
              key={item.id}
              student={item}
              onDelete={initDelete}
              toggleModal={packageProgModal}
            />
          ))}
          {studenLoading && <LoadGrid />}
        </div>
      </div>
      <Modal title={"Package Progress"} isOpen={isOpen} onCancel={closeModal}>
        {packageProg.map((item) => (
          <PackageStats item={item} key={item.id} />
        ))}
      </Modal>

      {students.length === 0 && !studenLoading && (
        <NoData title="No Students Found" />
      )}
    </Fragment>
  );
};

export default StudentList;
