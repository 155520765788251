import React , { useState, Fragment } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
const TeacherRenmForm = ({ subjects, hrSal, subjectSel, tblid, onSave }) => {

  const {register, handleSubmit} = useForm();
  const [selectedSub, setSelectedSub] = useState(null);
  const [salHr, setSalHr] = useState('');
  const handleChange = (data) => setSelectedSub(data);
  const handleInpChange = (e) => setSalHr(e.target.value);
  const addData = (data) => {
    onSave({ subject_id: selectedSub.id, rate_per_hr: data.salhr });
  };


  return (
    <Fragment>
      <form onSubmit={handleSubmit(addData)}>
        <div className="grid grid-cols-4 gap-3">
          <div className="lg:col-span-2">
            <label htmlFor="trname" className="package-label">
              Subject
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <Select
                options={subjects}
                isMulti={false}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={handleChange}
                className="w-full"
                value={selectedSub}
                hideSelectedOptions={true}
              />
            </div>
          </div>
          <div>
            <label htmlFor="salhr" className="package-label">
              Salary / Hr.
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="number"
                min={0}
                name="salhr"
                id="salhr"
                onChange={handleInpChange}
                className="package-inp"
                placeholder="Salary/Hr"
                {...register("salhr", { required: true, valueAsNumber: true })}
              />
            </div>
          </div>
          <div>
            <label htmlFor="salhr" className="package-label invisible">
              Button
            </label>
            <button
              type="submit"
              className="w-full bg-indigo-700 text-white h-9 leading-9 rounded-md hover:bg-indigo-900 transition-all"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default TeacherRenmForm;
