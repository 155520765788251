import React, { useState } from "react";
const SubjectHours = ({ subject, inpChange }) => {
  const [hours, setHours] = useState(subject ? subject.no_of_hrs : "");
  const handleInpChange = (e) => {
    setHours(e.target.value);
  };

  const handleInpBlur = (e) => {
    let changedData = {
      target: e.target.name,
      value: e.target.value,
      subjId: e.target.id,
    };
    inpChange(changedData);
  };
  return (
    <div className="md:flex justify-between items-center border-b-tiny border-slate-300 pb-3 block">
      <label
        htmlFor="company-website"
        className="block text-sm font-medium text-gray-700 mb-2"
      >
        Add Class for {subject.subject_name}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          type="number"
          name={subject.subject_name}
          id={subject.id}
          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full bg-gray-100 h-9 leading-9 px-3 rounded-md sm:text-sm border-gray-300"
          placeholder="Class"
          onBlur={handleInpBlur}
          value={hours}
          onChange={handleInpChange}
        />
      </div>
    </div>
  );
};

export default SubjectHours;
