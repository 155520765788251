import React from "react";
import { Link } from "react-router-dom";
import { CurrencyRupeeIcon, CalendarIcon, AcademicCapIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, TrashIcon, PencilIcon } from "@heroicons/react/solid";
const PackageCard = (props) => {
  const { id, name, descriptions, no_of_days, amount, created_at, subjects, hrs_class } =
  props.packages;
  const date = new Date(created_at).toLocaleDateString();

  const triggerDel = () => props.onDelete(props.packages);
  return (
    <div className="package-card">
      <div className="days-badge">
        <span className="font-medium text-white">{no_of_days}</span>
        <span className="text-xs relative bottom-1">Days</span>
      </div>
      <div className="py-3 px-5 mb-3">
        <h4 className="text-gray-400 text-sm">Package</h4>
        <h3 className="font-medium text-lg">{name}</h3>
      </div>
      <div className="bg-teal-100 p-3">
        <ul className="flex justify-between items-center">
          <li>
            <CurrencyRupeeIcon className="inline-block w-4 h-4 fill-white mr-1" />
            <span className="font-medium">{amount}</span>
          </li>
          <li>
            <AcademicCapIcon className="inline-block w-4 h-4 fill-white mr-1" />
            <span className="font-normal text-sm">{hrs_class + ' Mins / Class'}</span>
          </li>
        </ul>
      </div>
      <div className="py-3 px-5 mb-3">
        <h4 className="text-gray-400 text-sm">Description</h4>
        <h3 className="font-light text-sm">{descriptions}</h3>
      </div>
      <Disclosure>
        {({ open }) => (
          <React.Fragment>
            <Disclosure.Button
              disabled={subjects.length == 0}
              className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 text-center"
            >
              {subjects.length} Subjects
              <ChevronDownIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-purple-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pb-2 text-sm text-gray-500">
              {subjects.map((item, index) => (
                <div
                  className={`flex justify-between items-center text-sm py-2 px-4 ${
                    index % 2 >= 1 ? "bg-yellow-100" : ""
                  }`}
                  key={index}
                >
                  <span>{item.subject_name}</span>
                  <span>{item["no_of_hrs"]} Class</span>
                </div>
              ))}
            </Disclosure.Panel>
          </React.Fragment>
        )}
      </Disclosure>
      <div className="p-3">
        <ul className="flex justify-between items-center">
          <li>
            <Link to={`/edit-package/${id}`} className="sm-btn">
              <PencilIcon className="w-5 h-5 fill-white" />
            </Link>
          </li>
          <li>
            <button className="sm-btn bg-red-600" onClick={triggerDel}>
              <TrashIcon className="w-5 h-5 fill-white" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PackageCard;
