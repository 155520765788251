import React from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";
const StudentTeachers = ({subjects, totalHrs}) => {
    const {teachers} = subjects;
    const totalHrsAssigned = teachers.reduce((total, teacher) => {
       return (+teacher.no_of_hrs + +total);
    }, 0);
  return (
    <Disclosure defaultOpen={true} className="mb-2">
      {({ open }) => (
        <React.Fragment>
          <Disclosure.Button
            className="assigneeaccord mb-1 relative"
            disabled={!subjects.teachers.length > 0}
          >
            <span>
              {subjects.subject_name} ({subjects.no_of_hrs})
            </span>
            {totalHrs > totalHrsAssigned && teachers.length != 0 && <span className="text-xs absolute right-8">Teacher Assigned Pending</span>}
            {subjects.teachers.length > 0 && (
              <ChevronDownIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-4 h-4 text-purple-500`}
              />
            )}
            {teachers.length === 0 && <span className="text-xs">No Teachers Assigned</span>}
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500">
            <ul className="tutorUl">
              {teachers.map((teacher, index) => (
                <li key={index}>
                  <div>{teacher.name}</div>
                  <div className="ml-auto">{teacher.no_of_hrs}</div>
                </li>
              ))}
            </ul>
          </Disclosure.Panel>
        </React.Fragment>
      )}
    </Disclosure>
  );
};

export default StudentTeachers;
