import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/button";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from '../config/index';

let modalState = true;


const SubjectForm = (props) => {
  const {
    register,
    handleSubmit,
    resetField,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm();

  const { addSubject, editSubject } = ApiRoutes;

  let selSubjId = '';

  let url = '';

  // Check it is edit or add
  if(props.subject) {
    url = editSubject;
  } else {
    url = addSubject;
  }
  
  // Fetching Content for editing
  selSubjId = !!props.subject ? props.subject.id : '';
  useEffect(()=> {
     setValue('name', !!props.subject ? props.subject.name : '');
     setValue('short_name', !!props.subject ? props.subject['short_name'] : '');
     return () => {
       setValue('name', '');
       setValue('short_name', '');
      }
  },[])


  // Handling data after sucess
  const createSubject = useCallback((subject) => {
    props.onApply(subject.data, modalState);
    setValue('name', '');
    setValue('short_name', '');
  }, []);

  // useHttp
  const {sendRequest, isLoading, error} = useHttp(createSubject);

  // Handling Form Submit
  const submitForm = (data) => {

    // Addmore and Close popup
    const buttonType = window.event.submitter.name;
    if(buttonType === 'addmore'){
      modalState = false;
    }else{
      modalState = true;
    }

    resetField('name');
    resetField('short_name');
    let reqConfig = {
      url,
      method: 'POST',
      body: {id: selSubjId, ...data}
    }
    sendRequest(reqConfig);
  };



  return (
    <form onSubmit={handleSubmit(submitForm)} className="relative">
      <div className="mb-3">
        <label htmlFor="subj-name" className="sr-only">
          Subject
        </label>
        <input
          id="subj-name"
          name="name"
          type="text"
          required
          className={`form-inp ${errors.subject && "inp-error"}`}
          {...register("name", { required: true })}
          placeholder="Subject"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="subj-name-short" className="sr-only">
          Short Name
        </label>
        <input
          id="subj-name-short"
          name="name"
          type="text"
          required
          className={`form-inp ${errors.subject && "inp-error"}`}
          {...register("short_name", { required: true })}
          placeholder="Short Name"
        />
      </div>
      <div className="flex justify-between">
        {!props.subject && (
        <Button
          name="addmore"
          className="bg-red-500 text-white px-3 max-w-lg h-9 leading-9 rounded-lg text-sm relative"
        >
          Save & Addmore
        </Button>
        )}
        <Button
          className="bg-green-500 text-white px-3 max-w-lg h-9 leading-9 rounded-lg text-sm relative"
          name="submit"
        >
          Save & Close
        </Button>
      </div>
    </form>
  );
};

export default SubjectForm;
