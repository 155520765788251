import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthContext from "../context/auth-context";
const RoleAuth = ({ children }) => {
  let location = useLocation();
  const auth = useContext(AuthContext);
  if (!auth.user.token || auth.user.role == 1) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  return children;

};

export default RoleAuth;
