import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../hooks/use-http";
import StudPackage from "../components/student/package";
import Modal from "../components/modal";
import AssignPackage from "./AssignPackage";
import { ApiRoutes } from "../config";
import NoData from "../components/nodata";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth-context";

let tempValue = 0;

const StudentPackages = () => {
  const { id } = useParams();

  const [modal, setSowModal] = useState(false);

  const [curStud, setCurStud] = useState("");

  const [updateVal, setUpdateVal] = useState(null);

  const [assignedPackages, setassignedPackages] = useState([]);

  const { getStudents, editStudPack, updExpStudPack, studentPackage } =
    ApiRoutes;

  // Setting Common Filter For Student Package
  const store = useContext(AuthContext);

  const { packageFilter, changeFilter } = store;

  const [selectFilter, setSelectFilter] = useState(packageFilter);

  const _handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectFilter(value);
    changeFilter(value);
    tempValue = value;
  };

  const _filterPackageArr = (packages) => {
    let arr = packages;
    if(tempValue == 0){
      arr = packages.filter((x) => x.status == 1);
    }else{
      arr = packages;
    }
    setassignedPackages(arr);
  }

  const showModal = () => setSowModal(true);

  // Clear Update Value and modal
  const closeModal = () => {
    setSowModal(false);
    setUpdateVal(null);
  };

  // Status Update Api Handler
  const {
    sendRequest: _postStatus,
    isLoading: _statLoad,
    error: _statError,
  } = useHttp(() => {});

  // Package Status Upate
  const handleStatusUpdate = (item) => {
    _postStatus({ url: editStudPack, method: "POST", body: item });
  };

  // On Exp update Init
  const handleExpUpdate = (item) => {
    setSowModal(true);
    setUpdateVal(item);
  };

  // Fetch Student Packages
  const handlePackages = (res) => {
    let { packages } = res.data;
    _filterPackageArr(packages);
    setCurStud(res.data.name);
    if (packages.length == 0) setSowModal(true);

  };

  const { sendRequest } = useHttp(handlePackages);

  // Function to fetch
  const fetchStudentsPackage = () =>
    sendRequest({ url: `${getStudents}/${id}` });

  // Add a new Package for Student api handler
  const {
    sendRequest: _fetchStudAssignedPackages,
    isLoading: _studentPackLoad,
    error: _studentPackErr,
  } = useHttp(closeModal);

  const _newPackagehandler = (submitData) =>
    _fetchStudAssignedPackages({
      url: updateVal ? updExpStudPack : studentPackage,
      method: "POST",
      body: submitData,
    });

  useEffect(() => {
    fetchStudentsPackage();
    if ((_statLoad && _statError) || _studentPackLoad & _studentPackErr) {
      fetchStudentsPackage();
    }
    tempValue = selectFilter;
  }, [_statLoad, _statError, _studentPackLoad, _studentPackErr, selectFilter]);

  return (
    <Fragment>
      <div className="topSpacer"></div>

      <section className="container m-auto md:px-0 px-2">
        <div className="flex justify-end">
          <select
            onChange={_handleFilterChange}
            value={selectFilter}
            className="smSelect mb-3 max-w-xs w-full text-sm bg-white inline-block"
          >
            <option value="0">View Only Active Packages</option>
            <option value="1">View All Packages</option>
          </select>
        </div>
        <div className="flex md:justify-end justify-center mb-4 flex-wrap">
          <div className="mr-auto md:w-auto w-full">
            <h2 className="text-slate-400 mb-2 text-xs inline-block w-full">
              Packages for{" "}
              <span className="font-medium text-lg">{curStud}</span>
            </h2>
          </div>
          <Link
            to={`/assign-tutor/${id}`}
            className="h-9 leading-9 text-white bg-teal-500 px-4 rounded-md inline-block mr-2"
          >
            Assign Tr.
          </Link>
          <button
            onClick={showModal}
            className="h-9 leading-9 text-white bg-indigo-800 px-4 rounded-md"
          >
            Assign Package
          </button>
        </div>
        <div className="grid lg:grid-cols-3 gap-4 md:grid-cols-2 grid-cols-1">
          {assignedPackages.map((item, index) => (
            <StudPackage
              onStatusChange={handleStatusUpdate}
              onExpChange={handleExpUpdate.bind(this, item)}
              studPackage={item}
              key={item.id}
              studentId={id}
            />
          ))}
        </div>
        <Modal
          title={
            updateVal
              ? `Update Expiry Date ${updateVal.package_name}`
              : `Add new Package for ${curStud}`
          }
          isOpen={modal}
          onCancel={closeModal}
          hideClose={true}
        >
          <AssignPackage
            id={id}
            onDataSubmit={_newPackagehandler}
            editValue={updateVal}
            onCancel={closeModal}
          />
        </Modal>
      </section>
      {assignedPackages.length === 0 && _studentPackLoad && (
        <NoData title={`No Packages Assigned to ${curStud}`} />
      )}
    </Fragment>
  );
};

export default StudentPackages;
