import React, { useState, useEffect, useRef } from "react";
import { Tab } from "@headlessui/react";
import {
  TrashIcon,
  PencilIcon,
  CurrencyRupeeIcon,
  EyeIcon,
  EyeOffIcon,
  UserIcon
} from "@heroicons/react/outline";
import TutorStudent from "../components/tutor/tutor.student";
import Transactions from "../components/tutor/transactions";
import Modal from "../components/modal";
import PaymentEntry from "../modules/PaymentEntry";
import { useParams, Link } from "react-router-dom";
import AlertConfirm from "../components/confirmalert";
import { ApiRoutes } from "../config";
import useHttp from "../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { classNames } from "../helper/_helper";
import WorkLedgerPayment from "../components/tutor/workledgerpayment";

let hiddenPass = "";
const TutorDetails = () => {
  const { id } = useParams();

  const workLdgComp = useRef(null);

  const navigate = useNavigate();

  const [paymentModal, setPaymentModal] = useState(false);

  const [transUpdate, setTransUpdate] = useState("");

  const { delTeacher, getTeachers, getActiveStudents } = ApiRoutes;

  const [showAlert, setShowAlert] = useState(false);

  const [tutor, setTutor] = useState(null);

  const [logData, setLogData] = useState(null);

  const [activeStud, setActiveStud] = useState([]);

  const [showPass, setShowPass] = useState(false);

  const [lastPaidDate, setLastPaidDate] = useState(null);

  const showPaymentModal = () => setPaymentModal(true);

  const closePaymentModal = () => {
    setPaymentModal(false);
    setTransUpdate(new Date().getTime());
    workLdgComp.current._updateLedgerPay();
  };

  const _fetchActiveStudents = (data) => setActiveStud(data.data);

  const { sendRequest: fetchActiveStudents } = useHttp(_fetchActiveStudents);

  const handleConfirm = () =>
    delTutor({ url: delTeacher, method: "POST", body: { id: id } });

  const handleCancel = () => setShowAlert(false);

  const handleAfterDel = () => {
    setShowAlert(false);
    navigate("/teachers", { replace: true });
  };

  const printDetails = (res) => {
    setTutor(res.data);
    hiddenPass = res.data.password.replace(/./g, '*');
  }

  const { sendRequest } = useHttp(printDetails);

  const { sendRequest: delTutor, error, isLoading } = useHttp(handleAfterDel);

  const initDel = () => {
    setShowAlert(true);
  };

  const initPayment = (log) => {
    setLogData(log);
    setPaymentModal(true);
  };

  const togglePass = () => setShowPass((prevState) => !prevState)

  const getLastPaidDate = (lastPaidDt) => setLastPaidDate(lastPaidDt); 


  useEffect(() => {
    sendRequest({ url: `${getTeachers}/${id}` });
    fetchActiveStudents({ url: `${getActiveStudents}/${id}` });
  }, [id]);
  

  return (
    <section className="container m-auto md:px-0 px-2">
      <div className="topSpacer"></div>
      {tutor && (
        <div className="w-full max-w-5xl m-auto">
          <div className="tutor-details">
            <div>
              <h4 className="text-2xl font-medium text-slate-800">
                <span className="text-sm font-normal text-gray-600" hidden>
                  Mr.
                </span>
                {tutor.name}
                <span className="ml-2 text-xs text-slate-600 font-normal" title="Username">
                  (<span className="inline-block px-2">{tutor.teacher_id}</span>
                </span>
                <span className="text-xs text-slate-600 font-normal">
                  <span title="Password" className="px-2 inline-block">{showPass ? tutor.password : hiddenPass}</span>)
                  <span className="view-pass cursor-pointer" onClick={togglePass}>
                    {!showPass ? <EyeIcon className="w-4 h-4 inline-block ml-2" /> : <EyeOffIcon className="w-4 h-4 inline-block ml-2" />}
                  </span>
                </span>
              </h4>
              <h5 className="text-sm text-slate-600">
                {tutor.email} / {tutor.phone_no}
              </h5>
            </div>

            <div className="action-panel">
              <Link
                to={`/teacher-profile/${tutor.id}`}
                state={{ from: `/tutor-view/${tutor.id}` }}
                className="mr-3 flex items-center bg-blue-600 px-4 rounded h-10"
                title="Edit"
              >
                <UserIcon className="w-4 h-4 mr-2 stroke-white" />
                <h5 className="font-normal text-sm text-white">View Full Profile</h5>

              </Link>
              {/* <button
                className="round-btn bg-red-700 "
                title="Delete"
                onClick={initDel}
              >
                <TrashIcon className="w-4 h-4 m-auto  stroke-white" />
              </button> */}
            </div>
          </div>

          <div className="w-full">
            <Tab.Group>
              <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tab-list",
                      "tab-list-hover",
                      selected ? "tab-list-active" : "tab-list-normal"
                    )
                  }
                >
                  Active Students
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tab-list",
                      "tab-list-hover",
                      selected ? "tab-list-active" : "tab-list-normal"
                    )
                  }
                >
                  Payment History
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "tab-list",
                      "tab-list-hover",
                      selected ? "tab-list-active" : "tab-list-normal"
                    )
                  }
                >
                  Work Ledger
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel
                  className={classNames("tab-panel", "tab-panel-active")}
                >
                  <TutorStudent activeStudents={activeStud} />
                </Tab.Panel>
                <Tab.Panel
                  className={classNames("tab-panel", "tab-panel-active")}
                >
                  <Transactions id={id} updated={transUpdate} />
                </Tab.Panel>
                <Tab.Panel
                  className={classNames("tab-panel", "tab-panel-active")}
                >
                  <WorkLedgerPayment
                    onPayNow={initPayment}
                    onFetchLogSucess={getLastPaidDate}
                    teacherId={tutor.id}
                    activeStudents={activeStud}
                    ref={workLdgComp}
                    id={id}
                    lastPaymentDt={lastPaidDate}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      )}
      {tutor && lastPaidDate && (
        <Modal
          title={`Add new Payment - ${tutor.name}`}
          isOpen={paymentModal}
          onCancel={closePaymentModal}
        >
          <PaymentEntry
            id={id}
            onSucess={closePaymentModal}
            logData={logData}
            lastPaymentDt={lastPaidDate}
          />
        </Modal>
      )}
      {showAlert && (
        <AlertConfirm
          open={showAlert}
          title={`Delete`}
          message="Deleting can't be Reversed. Are You Sure ?"
          isLoading={isLoading}
          confirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </section>
  );
};

export default TutorDetails;
