import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import useHttp from "../hooks/use-http";
import { ApiRoutes } from "../config";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { CurrencyRupeeIcon } from "@heroicons/react/outline";

let today = "".concat(new Date().toISOString().split("T")[0]);

let date = new Date();
date.setDate(date.getDate() - 1);
let maxDate = date.toISOString().split("T")[0];
const PaymentEntry = ({ id, onSucess, logData, lastPaymentDt }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    getFieldState
  } = useForm();

  const { 
    getStatus, 
    getPaymentOptions, 
    addTrPayment, 
    fetchAmountToBePaidWithinDate 
  } = ApiRoutes;

  const [paymentStatus, setPaymentStatus] = useState([]);

  const [seletedOptions, setseletedOptions] = useState(null);

  const [paymentOptions, setPaymentOptions] = useState([]);

  const [ dateRange, setDateRange ] = useState({
    minDate: lastPaymentDt,
    maxDate: maxDate
  })

  const getPaymentStatus = (res) => {
    setPaymentStatus(res.data);
    setseletedOptions({ payment_status : res.data[0].id })
  }

  const fetchpaymentOptions = (res) => {
    setPaymentOptions(res.data);
  }

  const handelSelchange = (e, event) => {
    setseletedOptions((prevState) => ({
      ...prevState,
      [event.name]: e.id,
    }));
  };

  const handlePaymentSubmit = (res) => onSucess();

  const {
    sendRequest: fetchPayment,
    isLoading: paymentLoading,
    error: paymentError,
  } = useHttp(fetchpaymentOptions);

  const {
    sendRequest: fetchStatus,
    isLoading,
    error,
  } = useHttp(getPaymentStatus);

  const { sendRequest: entryPayment, isLoading: dataPostLoading } =
    useHttp(handlePaymentSubmit);

  const addPayment = (data) => {
    let formdata = { ...seletedOptions, ...data, teacher_id: id };
    entryPayment({ url: addTrPayment, method: "POST", body: formdata });
  };

  const {
    sendRequest: fetchAmountToPaid,
    isLoading: amount_loading
  } = useHttp((res) => setValue('amount', res.data))

  const handlePaymentDate = (e) => { 
    const end_date = e.target.value
    fetchAmountToPaid({
      url: fetchAmountToBePaidWithinDate,
      method: 'POST',
      body: {
        id: id,
        start_date: lastPaymentDt,
        end_date
      }
    })
  };

  useEffect(() => {
    fetchStatus({ url: getStatus });
    fetchPayment({ url: getPaymentOptions });
    setValue("payment_upto_date", maxDate);
    setValue("payment_date", today);
    setValue("amount", logData.totalAmount);
  }, [logData]);
  

  return (
    <form onSubmit={handleSubmit(addPayment)} className="text-sm">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-3">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Payment upto
          </label>
          <input
            name="payment_upto_date"
            className={`common-inp payment-entry ${errors.payment_upto_date && "inp-error"
              }`}
            {...register("payment_upto_date", {
              required: "Date is Required",
              valueAsDate: "Enter a Valid Date",
            })}
            placeholder="Date"
            type="date"
            min={dateRange.minDate}
            max={dateRange.maxDate}
            onChange={handlePaymentDate}
          />
          <ErrorMessage
            errors={errors}
            name="amount"
            render={({ message }) => <span className="err-msg">{message}</span>}
          />
        </div>
        <div className="payment-formgp">
          <label htmlFor="amount" className="common-label payment-label">
            Amount
          </label>
          <input
            id="amount"
            name="amount"
            type="text"
            readOnly
            min={0}
            className={`common-inp payment-entry ${errors.amount && "inp-error"}`}
            {...register("amount", {
              required: "Amount is Required",
              valueAsNumber: "Enter A Valid Number",
              min: "Enter a Value Greater than 0",
            })}
            placeholder="Amount"
          />
          <ErrorMessage
            errors={errors}
            name="amount"
            render={({ message }) => <span className="err-msg">{message}</span>}
          />
        </div>
      </div>
      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Payment Method
        </label>
        <Select
          options={paymentOptions}
          isMulti={false}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isSearchable={false}
          menuPlacement="bottom"
          isLoading={isLoading}
          onChange={handelSelchange}
          name="payment_method"
        />
      </div>
      <div className="payment-formgp">
        <label htmlFor="about" className="common-label payment-label">
          Description
        </label>
        <textarea
          id="about"
          name="payment_details"
          rows={3}
          className="common-textarea"
          placeholder="Description"
          {...register("payment_details", { required: false })}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div className="payment-formgp">
          <label htmlFor="date" className="common-label payment-label">
            Payment initiated date
          </label>
          <input
            id="date"
            name="payment_date"
            type="date"
            disabled
            className={`common-inp payment-entry`}
            {...register("payment_date", {
              required: false,
              valueAsDate: "Enter a Valid Date",
            })}
            placeholder="Date"
            defaultValue={today}
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Payment Status
          </label>
          <Select
            options={paymentStatus}
            isMulti={false}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable={false}
            value={paymentStatus[0]}
            menuPlacement="top"
            isLoading={isLoading}
            onChange={handelSelchange}
            name="payment_status"
            isDisabled
          />
        </div>
      </div>
      <div className="py-3 text-right">
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default PaymentEntry;
